import React from 'react';

import {
	StyledRadioInputContainer,
	StyledRadioLabel,
	StyledAsterisk,
	StyledRadioOptionsContainer,
	StyledRadioOptionsWrapper,
	StyledOption,
	StyledRadioInput,
} from './styles';

const RadioInput = (props) => {
	const { label, options, isRequired, isChecked, onChange } = props;
	return (
		<StyledRadioInputContainer>
			<StyledRadioLabel>
				{label} {isRequired && <StyledAsterisk>*</StyledAsterisk>}
			</StyledRadioLabel>
			<StyledRadioOptionsContainer>
				{options.map((option, index) => (
					<StyledRadioOptionsWrapper>
						<StyledRadioInput
							key={index}
							checked={isChecked}
							type='radio'
							options={option}
							onChange={onChange}
						/>
						<StyledOption>{option}</StyledOption>
					</StyledRadioOptionsWrapper>
				))}
			</StyledRadioOptionsContainer>
		</StyledRadioInputContainer>
	);
};

export default RadioInput;
