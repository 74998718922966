import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import {
	ManageSectionCon,
	ManageSectionContent,
	InputContainer,
	Input,
	FormWrapper,
	ErrorMessage,
} from './styles/ManageSectionStyles';

import { SubmitButton } from './styles/FormGen';

import { useFormTemplateContext, ACTIONS } from '../context';
import useEditSection from '../api/mutations/useEditSection';

import useAddSectionToTemplate from '../api/mutations/useAddSectionToTemplate';
const sectionSchema = z.object({
	section_name: z.string().nonempty('Section name is required'),
});

export const EditSection = (props) => {
	const {
		isOpen,
		setShowEditSection,
		traitTemplateId,
		sectionId,
		section_label,
		section_description,
		section_slug,
	} = props;
	const { state, dispatch } = useFormTemplateContext();
	const { formTemplate } = state;

	const editSection = useEditSection();

	const formik = useFormik({
		initialValues: {
			section_name: section_label,
			section_description: section_description,
			section_slug: section_slug,
		},
		onSubmit: (values) => handleFormSubmit(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(sectionSchema),
	});

	const handleFormSubmit = async (values) => {
		try {
			const updatedSections = await editSection.mutateAsync({
				sectionId,
				sectionLabel: values.section_name,
				sectionDescription: values.section_description,
				sectionSlug: values.section_slug,
			});

			dispatch({
				type: ACTIONS.SET_FORM_TEMPLATE,
				payload: {
					...formTemplate,
					sections: updatedSections,
				},
			});
			formik.resetForm();
			setShowEditSection(false);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<motion.div
			initial={{ x: '200%' }}
			animate={{ x: isOpen ? -50 : '200%' }}
			exit={{ x: '200%' }}
			transition={{ type: 'spring', stiffness: 300, damping: 30 }}
			style={{
				width: '380px',
				height: 'fit-content',
				backgroundColor: 'white',
				position: 'fixed',
				top: 20,
				bottom: 0,
				right: 0,
				zIndex: 1000,
				borderRadius: '8px',
				border: '1px solid rgba(43, 105, 166, 0.3)',
			}}
		>
			<ManageSectionCon>
				<h2>Edit Section</h2>
				<ManageSectionContent>
					<FormWrapper onSubmit={formik.handleSubmit}>
						<InputContainer>
							<Input
								name='section_name'
								type='text'
								onChange={formik.handleChange}
								placeholder='Section Name'
								value={formik.values.section_name}
							/>

							<ErrorMessage>
								{formik.errors.section_name && formik.errors.section_name}
							</ErrorMessage>
						</InputContainer>

						<InputContainer>
							<Input
								name='section_description'
								type='textarea'
								onChange={formik.handleChange}
								placeholder='Section Description'
								value={formik.values.section_description}
							/>

							<ErrorMessage>
								{formik.errors.section && formik.errors.section_description}
							</ErrorMessage>
						</InputContainer>

						<InputContainer>
							<Input
								name='section_slug'
								type='text'
								onChange={formik.handleChange}
								placeholder='Slug'
								value={formik.values.section_slug}
							/>

							<ErrorMessage>
								{formik.errors.section && formik.errors.section_slug}
							</ErrorMessage>
						</InputContainer>
						<SubmitButton
							type='submit'
							style={{
								margin: '0 auto',
							}}
						>
							<p>Save</p>
						</SubmitButton>
					</FormWrapper>
					<p
						onClick={() => setShowEditSection(false)}
						style={{
							color: 'black',
							textAlign: 'center',
							cursor: 'pointer',
							fontWeight: 'bold',
							margin: '0 auto',
						}}
					>
						Close
					</p>
				</ManageSectionContent>
			</ManageSectionCon>
		</motion.div>
	);
};
export const AddSection = (props) => {
	const { isOpen, setShowAddSection, traitTemplateId } = props;

	const { state, dispatch } = useFormTemplateContext();

	const { formTemplate } = state;

	const addSectionToTemplate = useAddSectionToTemplate();
	const formik = useFormik({
		initialValues: {
			section_name: '',
			section_slug: '',
		},
		onSubmit: (values) => handleFormSubmit(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(sectionSchema),
	});

	const handleFormSubmit = async (values) => {
		try {
			const newSection = await addSectionToTemplate.mutateAsync({
				traitTemplateId: traitTemplateId,
				sectionLabel: values.section_name,
				sectionDescription: values.section_description,
				sectionSlug: values.section_slug,
			});
			console.log('newSection', newSection);
			// get all the sectiosn in formTemplate
			const sections = formTemplate.sections;
			// add the new section to the sections array
			sections.push(newSection);
			// update the formTemplate with the new section
			dispatch({
				type: ACTIONS.SET_FORM_TEMPLATE,
				payload: {
					...formTemplate,
					sections,
				},
			});
			formik.resetForm();
			setShowAddSection(false);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<motion.div
			initial={{ x: '200%' }}
			animate={{ x: isOpen ? -50 : '200%' }}
			exit={{ x: '200%' }}
			transition={{ type: 'spring', stiffness: 300, damping: 30 }}
			style={{
				width: '380px',
				height: 'fit-content',
				backgroundColor: 'white',
				position: 'fixed',
				top: 20,
				bottom: 0,
				right: 0,
				zIndex: 1000,
				borderRadius: '8px',
				border: '1px solid rgba(43, 105, 166, 0.3)',
			}}
		>
			<ManageSectionCon>
				<h2>Add a Section</h2>

				<ManageSectionContent>
					<FormWrapper onSubmit={formik.handleSubmit}>
						<InputContainer>
							<Input
								name='section_name'
								type='text'
								onChange={formik.handleChange}
								placeholder='Section Name'
								value={formik.values.section_name}
							/>

							<ErrorMessage>
								{formik.errors.section_name && formik.errors.section_name}
							</ErrorMessage>
						</InputContainer>

						<InputContainer>
							<Input
								name='section_description'
								type='textarea'
								onChange={formik.handleChange}
								placeholder='Section Description'
								value={formik.values.section_description}
							/>

							<ErrorMessage>
								{formik.errors.section && formik.errors.section_description}
							</ErrorMessage>
						</InputContainer>

						<InputContainer>
							<Input
								name='section_slug'
								type='text'
								onChange={formik.handleChange}
								placeholder='Slug'
								value={formik.values.section_slug}
							/>

							<ErrorMessage>
								{formik.errors.section && formik.errors.section_slug}
							</ErrorMessage>
						</InputContainer>
						<SubmitButton
							type='submit'
							style={{
								margin: '0 auto',
							}}
						>
							<p>Add Section</p>
						</SubmitButton>
					</FormWrapper>
					<p
						onClick={() => setShowAddSection(false)}
						style={{
							color: 'black',
							textAlign: 'center',
							cursor: 'pointer',
							fontWeight: 'bold',
							margin: '0 auto',
						}}
					>
						Close
					</p>
				</ManageSectionContent>
			</ManageSectionCon>
		</motion.div>
	);
};
