import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';

import { useAuth } from '../../hooks/useAuth';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import MenuButton from './MenuButton';
import LoginIndicator from './LoginIndicator';
import DropDownMenu from './DropDownMenu';

import {
	StyledBanner,
	StyledHeader,
	StyledWrapper,
	StyledLogo,
	StyledNavButtonsWrapper,
	StyledNavText,
} from './styles';

const NavBar = (props) => {
	const { isAuthenticated, hasCompletedPlatformOnboarding, statusUpdate, hasDocumentsApproval } =
		props;
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [logoURL, setLogoURL] = useState(
		'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png'
	);
	const history = useHistory();

	const dropDownHandler = () => {
		setIsDropDownOpen(!isDropDownOpen);
	};

	const goToHomePage = () => {
		history.push(coreRoutes.root);
	};

	const goToLoginPage = () => {
		history.push(authRoutes.login);
	};

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};

	const goToOffersPage = () => {
		history.push(coreRoutes.offers);
	};

	const goToJobPostsPage = () => {
		history.push(coreRoutes.jobPosts);
	};

	const goToChatPage = () => {
		history.push(coreRoutes.chat);
	};

	const goToDashbboardPage = () => {
		history.push(coreRoutes.dashboard);
	};

	const goToDocumentsPage = () => {
		history.push(coreRoutes.documents);
	};

	return (
		<>
			{statusUpdate && <StyledBanner>{statusUpdate}</StyledBanner>}

			<StyledHeader>
				<StyledWrapper>
					<StyledLogo onClick={goToHomePage} src={logoURL} />

					<StyledNavButtonsWrapper>
						{isAuthenticated &&
							(!hasCompletedPlatformOnboarding || !hasDocumentsApproval) && (
								<>
									<TransparentButton onClick={goToDocumentsPage}>
										<StyledNavText>Documents</StyledNavText>
									</TransparentButton>

									<TransparentButton onClick={goToDashbboardPage}>
										<StyledNavText>Dashboard</StyledNavText>
									</TransparentButton>
								</>
							)}

						{isAuthenticated &&
							hasCompletedPlatformOnboarding &&
							hasDocumentsApproval && (
								<>
									<TransparentButton onClick={goToJobPostsPage}>
										<StyledNavText>Care Jobs</StyledNavText>
									</TransparentButton>

									<TransparentButton onClick={goToOffersPage}>
										<StyledNavText>Offers</StyledNavText>
									</TransparentButton>

									<TransparentButton onClick={goToChatPage}>
										<StyledNavText>Chat</StyledNavText>
									</TransparentButton>
								</>
							)}

						{!isAuthenticated && (
							<>
								<TransparentButton size='small' onClick={goToOnboardingPage}>
									Signup
								</TransparentButton>
								<LoginIndicator onClick={goToLoginPage} />
							</>
						)}

						{isAuthenticated &&
							hasCompletedPlatformOnboarding &&
							hasDocumentsApproval && <LoginIndicator onClick={dropDownHandler} />}

						{isDropDownOpen && <DropDownMenu dropDownHandler={dropDownHandler} />}
					</StyledNavButtonsWrapper>
				</StyledWrapper>
			</StyledHeader>
		</>
	);
};

export default NavBar;
