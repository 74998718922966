import React from 'react';

import { StyledFieldGroup, StyledTextLabel, StyledAsterisk, StyledTextInput } from './styles';

const TextInput = (props) => {
	const { label, isRequired } = props;

	return (
		<StyledFieldGroup>
			<StyledTextLabel>
				{label} {isRequired && <StyledAsterisk>*</StyledAsterisk>}
			</StyledTextLabel>
			<StyledTextInput {...props} />
		</StyledFieldGroup>
	);
};

export default TextInput;
