import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import AdminApp from './admin/AdminApp';
import AppOverlay from './AppOverlay';
import ProviderApp from './provider/ProviderApp';
import ProviderOnboard from './provider/POnboard';
import ClientApp from './client';
import CareProApp from './carePro';

import PatientRouteHandler from './patient/PatientRouteHandler';

import { GlobalStyles } from './styled/Fonts';
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ToastContainer } from 'react-toastify';
import { device } from 'AppComp/styled/StyledMediaQuery';

import PrivacyPolicy from './PrivacyPolicy';

export const CAL_LOCALIZER = momentLocalizer(moment);
const navHeight = '80px';
const AppContainer = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.Toastify__progress-bar {
		background-color: #2b69a3;
	}

	.Toastify__progress-bar-theme--light {
		background-color: #2b69a3;
	}
`;

import CareProOnboardParent from './provider/CareProOnboard';

/**
	This app was built with route based components in mind,
	Common pattern her is to find the route then follow hierarchy down to find whatever treasure you're looking for

	idea for the future was to split all the top level routes (ex: admin app, provider app, etc..) into different chunks in order to reduce bundle size and unnecessary load time for users

	@brgarciarivas
*/
export const DEFAULT_TOAST_OPTIONS = {
	containerId: 'basic',
	style: {
		zIndex: 10000001,
	},
};

const LargeToastContainer = styled(ToastContainer)`
  &&&.LargeToast {
    width: auto;
    max-width: 85vw;
    border-radius: 12px;
    padding: 0 !important;
		overflow: auto;
		overflow-x: hidden;
  }
  &&&.ToastLarge {
    max-height; 90vh;
    border-radius: 12px;
    padding: 0 !important;
  }
	&&&..Toastify__toast{
		margin-bottom: 0px !important;
	}
	.Toastify__progress-bar {
		background: #2b69a3;	
	}

	.Toastify__progress-bar-theme--light {
		background: #2b69a3;
	}

	@media ${device.tablet} {
		max-height: 90vh;
	}
`;

const App = (props) => {
	return (
		<AppContainer>
			<LargeToastContainer
				toastClassName={'ToastLarge'}
				className={'LargeToast'}
				enableMultiContainer={true}
				containerId={'LargeToast'}
			/>
			<GlobalStyles />
			<AppOverlay />
			<Switch>
				<Route
					path='/registry'
					children={(props) => {
						return <AdminApp {...props} />;
					}}
				/>
				{/* <Route
					path='/carepro'
					children={(props) => {
						return <ProviderApp {...props} />;
					}}
				/> */}
				{/* <Route
					path='/carepro-welcome'
					children={(props) => {
						return <CareProOnboardParent {...props} />;
					}}
				/> */}
				<Route
					path='/care-pro'
					children={(props) => {
						return <CareProApp {...props} />;
					}}
				/>
				<Route
					path='/privacy-notice'
					children={(props) => {
						return <PrivacyPolicy {...props} />;
					}}
				/>
				<Route
					path='/client'
					children={(props) => {
						return <ClientApp {...props} />;
					}}
				/>
				<Route
					path={[
						'/',
						'/verifyAccount',
						'/search/:page',
						'/viewprovider/:caregiverId',
						'/careplan/:careplanId',
						'/careplan',
						'/profile',
						'/shift/:shiftId',
						'/calendar',
					]}
					children={(props) => {
						return <PatientRouteHandler navHeight={navHeight} {...props} />;
					}}
				/>
			</Switch>
		</AppContainer>
	);
};

export default withRouter(App);
