import styled from 'styled-components';

import { colors } from '../../../../../../corev2/utils';

export const StyledLoginIndicator = styled.button`
	width: auto;
	padding: 0.75rem;
	background-color: ${({ isActive }) => (isActive ? colors.primary.primary : colors.white)};
	border-radius: 1.5rem;
	border-width: 1px;
	letter-spacing: 0.5;
	text-align: center;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
`;

export const StyledUsername = styled.h3`
	font-size: 16px;
	color: ${({ isActive }) => (isActive ? colors.white : colors.primary.primary)};
`;

export const StyledIconContainer = styled.div`
	width: auto;

	color: ${({ isActive }) => (isActive ? colors.white : colors.primary.primary)};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
