import styled from 'styled-components';

import { colors } from '../../../../../../corev2/utils';

export const StyledCheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const StyledCheckboxInput = styled.input`
	border-color: ${colors.forms.formInputBorderColor};
	border-radius: 4px;
	background: ${colors.white};

	display: inline-block;
	margin: 0 5px 0 0;

	width: 16px;
	height: 16px;

	border-style: solid;
	border-width: 1px;
	box-shadow: none;
	font-size: inherit;
	outline: none;

	&:checked {
		background: ${colors.primary.primary};
	}
`;

export const StyledCheckboxLabel = styled.label`
	color: ${colors.forms.formLabelColor};
	font-size: 1rem;
	line-height: 1.5;
	margin: 0;
	padding: 0;
`;
