import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../../../corev2/Text';
import Loader from '../../../../../corev2/Loader';
import ShiftItem from '../../../core/components/ShiftItem';

import { useFetchCarePlanShiftsQuery } from '../../../core/api/queries/useFetchCarePlanShiftsQuery';
import { useFetchClientActiveShiftQuery } from '../../api/queries/useFetchClientActiveShiftQuery';

import { formatIncomingShift, sortShifts } from '../../../core/lib';
import { getShiftCategories } from '../../lib';

import { shiftsRoutes } from '../../routes/constants';

import {
	StyledShiftWrapper,
	StyledPageHeadingContainer,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledShiftText,
} from './styles';

const ShiftsPage = () => {
	const [activeShift, setActiveShift] = useState(null);
	const [upcomingShifts, setUpcomingShifts] = useState([]);
	const [submittedShifts, setSubmittedShifts] = useState([]);
	const [futureShifts, setFutureShifts] = useState([]);
	const [paidShifts, setPaidShifts] = useState([]);
	const [canceledShifts, setCanceledShifts] = useState([]);
	const [disputedShifts, setDisputedShifts] = useState([]);
	const history = useHistory();

	const { data: shifts, isLoading: isLoadingShifts } = useFetchCarePlanShiftsQuery();
	const { data: incomingActiveShift, isLoading: isLoadingActiveShift } =
		useFetchClientActiveShiftQuery();

	useEffect(() => {
		if (!isLoadingActiveShift && incomingActiveShift) {
			const formattedActiveShift = incomingActiveShift
				? formatIncomingShift(incomingActiveShift)
				: null;
			setActiveShift(formattedActiveShift);
		}
	}, [incomingActiveShift, isLoadingActiveShift]);

	useEffect(() => {
		if (!isLoadingShifts && shifts.length > 0) {
			const {
				upcomingShifts: newUpcomingShifts,
				submittedShifts: newSubmittedShifts,
				futureShifts: newFutureShifts,
				canceledShifts: newCanceledShifts,
				paidShifts: newPaidShifts,
				disputedShifts: newDisputedShifts,
			} = getShiftCategories(shifts);

			const formattedUpcomingShifts = newUpcomingShifts.map(formatIncomingShift);
			const formattedSubmittedShifts = newSubmittedShifts.map(formatIncomingShift);
			const formattedFutureShifts = newFutureShifts.map(formatIncomingShift);
			const formattedPaidShifts = newPaidShifts.map(formatIncomingShift);
			const formattedCanceledShifts = newCanceledShifts.map(formatIncomingShift);
			const formattedDisputedShifts = newDisputedShifts.map(formatIncomingShift);

			setUpcomingShifts(sortShifts(formattedUpcomingShifts));
			setSubmittedShifts(sortShifts(formattedSubmittedShifts));
			setFutureShifts(sortShifts(formattedFutureShifts));
			setPaidShifts(sortShifts(formattedPaidShifts));
			setCanceledShifts(sortShifts(formattedCanceledShifts));
			setDisputedShifts(sortShifts(formattedDisputedShifts));
		}
	}, [shifts, isLoadingShifts]);

	const goToShiftPage = (shiftId) => {
		history.push(shiftsRoutes.shift(shiftId));
	};

	if (isLoadingShifts || isLoadingActiveShift) return <Loader />;

	return (
		<StyledShiftWrapper>
			<StyledPageHeadingContainer>
				<PageHeading> Shifts</PageHeading>
				<Text>
					Manage your assigned shifts by viewing the details of each shift category below.
					You can see active shifts, assigned shifts, submitted shifts, paid shifts,
					future shifts, canceled and disputed shifts. You can also get a better overview
					by going to your care plan's schedule page.
				</Text>
			</StyledPageHeadingContainer>

			<StyledShiftContainer>
				<StyledTitle>ACTIVE SHIFT</StyledTitle>
				<StyledShiftDetails>
					{activeShift && (
						<ShiftItem
							isAvailable={true}
							shift={activeShift}
							onClickHandler={() => goToShiftPage(activeShift.id)}
						/>
					)}

					{!activeShift && <StyledShiftText>No active shift.</StyledShiftText>}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>UPCOMING SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{upcomingShifts.map((upcomingShift) => (
						<ShiftItem
							isAvailable={true}
							key={upcomingShift.id}
							shift={upcomingShift}
							onClickHandler={() => goToShiftPage(upcomingShift.id)}
						/>
					))}

					{upcomingShifts.length === 0 && (
						<StyledShiftText>You have no upcoming shifts.</StyledShiftText>
					)}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>SUBMITTED SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{submittedShifts.map((submittedShift) => (
						<ShiftItem
							isAvailable={false}
							key={submittedShift.id}
							shift={submittedShift}
							onClickHandler={() => goToShiftPage(submittedShift.id)}
						/>
					))}

					{submittedShifts.length === 0 && (
						<StyledShiftText>No submitted shifts.</StyledShiftText>
					)}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>PAID SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{paidShifts.map((paidShift) => (
						<ShiftItem
							isAvailable={false}
							key={paidShift.id}
							shift={paidShift}
							onClickHandler={() => goToShiftPage(paidShift.id)}
						/>
					))}

					{paidShifts.length === 0 && <StyledShiftText>No paid shifts.</StyledShiftText>}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>FUTURE SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{futureShifts.map((futureShift) => (
						<ShiftItem
							isAvailable={true}
							shift={futureShift}
							onClickHandler={() => goToShiftPage(futureShift.id)}
						/>
					))}

					{futureShifts.length === 0 && (
						<StyledShiftText>No futures shifts scheduled.</StyledShiftText>
					)}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>DISPUTED SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{disputedShifts.map((disputedShift) => (
						<ShiftItem isAvailable={false} shift={disputedShift} />
					))}

					{disputedShifts.length === 0 && (
						<StyledShiftText>No disputed shifts.</StyledShiftText>
					)}
				</StyledShiftDetails>
			</StyledShiftContainer>

			<StyledShiftContainer>
				<StyledTitle>CANCELED SHIFTS</StyledTitle>
				<StyledShiftDetails>
					{canceledShifts.map((canceledShift) => (
						<ShiftItem isAvailable={false} shift={canceledShift} />
					))}

					{canceledShifts.length === 0 && (
						<StyledShiftText>No canceled shifts.</StyledShiftText>
					)}
				</StyledShiftDetails>
			</StyledShiftContainer>
		</StyledShiftWrapper>
	);
};

export default ShiftsPage;
