import React from 'react';

import { StyledCheckboxContainer, StyledCheckboxInput, StyledCheckboxLabel } from './styles';

const Checkbox = (props) => {
	const { name, label, isChecked, onChange } = props;

	return (
		<StyledCheckboxContainer>
			<StyledCheckboxInput
				name={name}
				type='checkbox'
				checked={isChecked}
				onChange={onChange}
			/>
			<StyledCheckboxLabel>{label}</StyledCheckboxLabel>
		</StyledCheckboxContainer>
	);
};

export default Checkbox;
