import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import LEGACY_Banking from '../../../components/LEGACY_Banking';

import { useUpdateCareProStripeInformationMutation } from '../../../../core/api/mutations/useUpdateCareProStripeInformationMutation';
import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';

import { authRoutes } from '../../../routes/constants';

import { StyledPayoutsPage } from './styles';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const PayoutsPage = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');

	const [loading, setLoading] = useState(false);

	const { stripeOnboardingState, mutateStripeOnboardingState } = useStripeOnboarding();
	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();

	const payoutsHandler = async (values) => {
		try {
			setLoading(true);
			await updateCareProStripeInformation.mutateAsync(values);

			mutateStripeOnboardingState('payouts', {
				...stripeOnboardingState.payouts,
				isCompleted: true,
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Phone / email verification failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.stripe.address);
	};

	return (
		<StyledPayoutsPage>
			<PageHeading>Account Information</PageHeading>

			<Elements stripe={stripePromise}>
				<LEGACY_Banking loading={loading} updateProviderVerificationInfo={payoutsHandler} />
			</Elements>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledPayoutsPage>
	);
};

export default PayoutsPage;
