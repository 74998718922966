import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

export const MIN_TRAIT_TEMPLATE_MODEL = `
  id,
  template_label,
  registry_id,
  template_type,
  description,
`;

export const TRAIT_MODEL = `
  id,
  label,
  required,
  active,
  trait_type,
  description,
  meta_data,
  section_id,
  status
`;

export const TRAIT_TEMPLATE_SECTION_MODEL = `
  id,
  label,
  order_index,
  traits {
      ${TRAIT_MODEL}
  }
`;

const TRAIT_TEMPLATE_MODEL = `
  ${MIN_TRAIT_TEMPLATE_MODEL},
  sections {
    ${TRAIT_TEMPLATE_SECTION_MODEL}
  }
`;

export const FORM_ANSWERS_MODEL = `
  id,
  trait_value,
  trait_id,
  version,
  trait_type
`;
export const FORM_MODEL = `
  id,
  trait_template_id,
  trait_template {
    ${TRAIT_TEMPLATE_MODEL}
  }
  form_answers {
    ${FORM_ANSWERS_MODEL}
  }

`;
const fetchCarePlanCareReceiverInfo = async () => {
	try {
		const data = await api.graph({
			query: `{
        checkUserSession {
          carePlan {
            receiver_info {
              ${FORM_MODEL}
            }
          }
        }
      }`,
		});

		return data.checkUserSession.carePlan.receiver_info;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

const fetchCarePlanContactInfo = async () => {
	try {
		const data = await api.graph({
			query: `{
        checkUserSession {
          carePlan {
            contact_info {
              ${FORM_MODEL}
            }
          }
        }
      }`,
		});

		return data.checkUserSession.carePlan.contact_info;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

const fetchCarePlanLocationInfo = async () => {
	try {
		const data = await api.graph({
			query: `{
        checkUserSession {
          carePlan {
            location_info {
              ${FORM_MODEL}
            }
          }
        }
      }`,
		});

		return data.checkUserSession.carePlan.location_info;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

const fetchCarePlanCareNeeds = async () => {
	try {
		const data = await api.graph({
			query: `{
        checkUserSession {
          carePlan {
            needs_info {
              ${FORM_MODEL}
            }
          }
        }
      }`,
		});

		return data.checkUserSession.carePlan.needs_info;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

export const useFetchCareReceiverInfo = () => {
	return useQuery({
		queryKey: ['fetchCareProSkills'],
		queryFn: () => {
			return fetchCarePlanCareReceiverInfo();
		},
		retry: false,
	});
};

export const useFetchCarePlanContactInfo = () => {
	return useQuery({
		queryKey: ['fetchCareProSkills'],
		queryFn: () => {
			return fetchCarePlanContactInfo();
		},
		retry: false,
	});
};

export const useFetchCarePlanLocationInfo = () => {
	return useQuery({
		queryKey: ['fetchCareProSkills'],
		queryFn: () => {
			return fetchCarePlanLocationInfo();
		},
		retry: false,
	});
};

export const useFetchCarePlanCareNeeds = () => {
	return useQuery({
		queryKey: ['fetchCareProSkills'],
		queryFn: () => {
			return fetchCarePlanCareNeeds();
		},
		retry: false,
	});
};
