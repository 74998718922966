const liveInsKeys = {
	number_of_break_hours: 'number_of_break_hours',
	number_of_sleep_hours: 'number_of_sleep_hours',
	live_in_state: 'live_in_state',
	daily_rate: 'daily_rate',
	number_of_days_of_care: 'number_of_days_of_care',
	number_of_care_pros_required: 'number_of_care_pros_required',
	private_bedroom_availalable: 'private_bedroom_availalable',
	private_bathroom_available: 'private_bathroom_available',
	travel_miles_compensated: 'travel_miles_compensated',
	meals_provided: 'meals_provided',
	below_minimum_wage_acknowledgment: 'below_minimum_wage_acknowledgment',
	below_minimum_rest_time_acknowledgment: 'below_minimum_rest_time_acknowledgment',
};

export default liveInsKeys;
