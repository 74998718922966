import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AddressPage from './AddressPage';
import PayoutsPage from './PayoutsPage';
import PersonalInfoPage from './PersonalInfoPage';
import TOSPage from './TOSPage';

import Steps from '../../../../../corev2/Steps';

import { StripeOnboardingProvider } from '../../context/StripeOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useStripeOnboarding } from '../../hooks/useStripeOnboarding';

import { authRoutes } from '../../routes/constants';

import { StyledStripeOnboardingPage, StyledStepsContainer } from './styles';

const StripeOnboardingContent = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const { isAuthenticated } = useAuth();
	const { stripeOnboardingState } = useStripeOnboarding();

	useLayoutEffect(() => {
		if (!isAuthenticated) return history.push(authRoutes.login);

		const steps = Object.values(stripeOnboardingState);
		const currentStep = steps.find((step) => !step.isCompleted);

		if (currentStep && location.pathname !== currentStep.route) {
			history.push(currentStep.route);
		} else if (!currentStep && location.pathname !== authRoutes.onboarding.home) {
			history.push(authRoutes.onboarding.home);
		}
	}, [isAuthenticated, stripeOnboardingState, history]);

	return (
		<StyledStripeOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={stripeOnboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledStripeOnboardingPage>
	);
};

const StripeOnboardingPage = (props) => {
	return (
		<StripeOnboardingProvider>
			<StripeOnboardingContent {...props} />
		</StripeOnboardingProvider>
	);
};

export { AddressPage, PayoutsPage, PersonalInfoPage, TOSPage };
export default StripeOnboardingPage;
