import { useMutation } from '@tanstack/react-query';

import api from 'scripts/api';

import { TRAIT_TEMPLATE_SECTION_MODEL } from '../models';
const addSectionToTemplate = async ({
	traitTemplateId,
	sectionLabel,
	sectionDescription,
	sectionSlug,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
        addSectionToTemplate ( 
        	template_id: ${traitTemplateId}, 
        	section_label: "${sectionLabel}",
			section_description: "${sectionDescription}",
			section_slug: "${sectionSlug}",
        ) {
          ${TRAIT_TEMPLATE_SECTION_MODEL}
        }
      }`,
		});

		return data.addSectionToTemplate;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

const useAddSectionToTemplate = () => {
	return useMutation({
		mutationKey: ['addSectionToTemplate'],
		mutationFn: (values) => {
			return addSectionToTemplate(values);
		},
	});
};

export default useAddSectionToTemplate;
