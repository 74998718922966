import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';
import moment from 'moment';

import { TransparentButton } from '../../../../../corev2/Buttons';
import Loader from '../../../../../corev2/Loader';

import { useCancelShiftMutation } from '../../api/mutations/useCancelShiftMutation';

import {
	StyledShiftWrapper,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledFieldGroup,
	StyledFieldLabel,
	StyledButtonGroup,
	StyledValue,
} from './styles';

import TimerSection from '../../components/TimerSection';
import ShiftNotes from '../../components/ShiftNotes';

import { shiftsRoutes } from '../../routes/constants';

import ShiftProvider, { useShiftContext } from '../../context/ShiftContext';

const ShiftPageContent = (props) => {
	const [loadingClock, setLoadingClock] = useState(false);
	const history = useHistory();

	const { shift, isLoadingShift, refreshShift } = useShiftContext();

	const cancelShift = useCancelShiftMutation();

	const cancelShiftHandler = async () => {
		try {
			await cancelShift.mutateAsync({
				shiftId: shift.id,
			});

			history.push(shiftsRoutes.home);
		} catch (error) {
			console.error('Submit shift failed', error);
		}
	};

	if (isLoadingShift) {
		return <Loader />;
	}

	return (
		<>
			<StyledShiftWrapper>
				<StyledShiftContainer>
					<StyledTitle>SHIFT DETAILS</StyledTitle>
					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Status</StyledFieldLabel>
							<StyledValue>{shift.status}</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>
					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Scheduled Clock In</StyledFieldLabel>
							<StyledValue>
								{moment(shift.start_time).format('MMMM D, YYYY [at] h:mm A')}
							</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>

					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Scheduled Clock Out</StyledFieldLabel>
							<StyledValue>
								{moment(shift.end_time).format('MMMM D, YYYY [at] h:mm A')}
							</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>

					{shift.status === 'clocked_in' && (
						<TimerSection startDate={new Date(shift.clock_in_time)} />
					)}

					<StyledButtonGroup>
						{/* Show "Cancel Shift" button if shift is assigned */}
						{shift.status === 'assigned' && (
							<TransparentButton onClick={cancelShiftHandler} size='small'>
								Cancel Shift
							</TransparentButton>
						)}
					</StyledButtonGroup>
				</StyledShiftContainer>

				{shift.status === 'submitted' && (
					<>
						<ShiftNotes />
					</>
				)}
			</StyledShiftWrapper>
		</>
	);
};

const ShiftPage = () => {
	return (
		<ShiftProvider>
			<ShiftPageContent />
		</ShiftProvider>
	);
};

export default ShiftPage;
