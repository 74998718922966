import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import Colors from 'colors';

import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import SearchDropDownHandler from 'AppComp/SearchDropDownHandler';
import Img from 'AppComp/Img';

const Container = styled.div`
	background-color: ${Colors.fullWhite};
	border-radius: 4px;
	width: 600px;
	height: 85%;
	margin-left: 32px;
`;

const CustomImage = styled(Img)`
	height: 70px;
	width: 70px;
	image-orientation: ;
`;

export default class ProviderSearchController extends Component {
	state = {
		registryApproved: false,
	};

	componentDidMount() {
		const { searchProviders, showInfoModel } = this.props;
		searchProviders();
	}

	handleChange = (selectedOption) => {
		this.setState({ registryApproved: selectedOption === 'approved' ? true : false });
	};

	handleSearchSubmit = (input) => {
		const { searchProviders } = this.props;

		searchProviders(input);
	};

	loadOptions = (inputValue) => null;

	renderSearchBar = () => {
		const { selectedOption, options } = this.state;

		return (
			<Container>
				<SearchDropDownHandler
					options={options}
					handleChange={this.loadOptions}
					handleSearchSubmit={this.handleSearchSubmit}
					placeholder='Search Providers'
					showSuggestions
				/>
			</Container>
		);
	};

	renderRows = () => {
		const { providers, fetchProivder, goToProvider } = this.props;
		console.log('0x01', providers);
		if (providers.length === 0) {
			return (
				<SetTable.ColumnCon>
					<Text color='#969696' fontWeight='100' mediumText margin='16px'>
						No providers found.
					</Text>
				</SetTable.ColumnCon>
			);
		} else {
			return (
				providers
					// .filter((d) => {
					// 	if (this.state.registryApproved) {
					// 		return d.careProRegistryStatus.registry_approval === true;
					// 	}
					// 	return d.careProRegistryStatus.registry_approval === false;
					// })
					.map((d, i) => {
						console.log('providers', d);

						return (
							<SetTable.RowAction>
								<SetTable.ColumnCon flex='1'>
									{d.image_url ? (
										<CustomImage src={d.image_url} borderRadius='50%' />
									) : (
										<div
											style={{
												background: Colors.primary.primary_light_shade,
												width: '70px',
												height: '70px',
												borderRadius: '50%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<FontAwesomeIcon
												icon={faUser}
												color={Colors.primary.primary}
											/>
										</div>
									)}
								</SetTable.ColumnCon>
								<SetTable.ColumnCon flex='2'>
									<Text color='#2D2D2D' fontWeight='100' mediumText>
										{`${d.first_name} ${d.last_name}`}{' '}
										<span
											style={{
												fontWeight: 'normal',
												fontStyle: 'italic',
												fontSize: '10px',
												color: 'black',
											}}
										>
											{d.display_name ? `(${d.display_name})` : ''}
										</span>
									</Text>
								</SetTable.ColumnCon>

								<SetTable.ColumnCon flex='1'>
									<Text color='#2D2D2D' fontWeight='100' mediumText>
										{d.careProRegistryStatus?.registry_approval
											? 'Approved'
											: 'Pending'}
									</Text>
								</SetTable.ColumnCon>

								<SetTable.ColumnCon>
									<Button
										width='200px'
										color={Colors.blue}
										onClick={() => goToProvider(d.id)}
										style={{
											boxShadow: 'none',
											borderRadius: '16px',
											border: `1px solid ${Colors.blue}`,
										}}
									>
										View Provider Info
									</Button>
								</SetTable.ColumnCon>
							</SetTable.RowAction>
						);
					})
			);
		}
	};

	renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Image
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Name
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='1'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Registry Approved
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon />
			</>
		);
	};
}
