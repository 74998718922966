import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const fetchClientActiveShift = async () => {
	try {
		const data = await api.graph({
			query: `{
            fetchClientActiveShift {
					${SHIFT_MODEL}
                }
            }`,
		});

		return data.fetchClientActiveShift;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchClientActiveShiftQuery = () => {
	return useQuery({
		queryKey: ['fetchClientActiveShift'],
		queryFn: () => {
			return fetchClientActiveShift();
		},
	});
};
