import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUtensils, faBed, faBath, faCar } from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton, SecondaryButton } from '../../Buttons';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledLocation,
	StyledDescriptionContainer,
	StyledDescription,
	StyledButtonContainer,
} from './styles';

const ClientTile = (props) => {
	const {
		id,
		name,
		hourlyRate,
		jobDescription,
		careType,
		mealsProvided,
		bedroomAvailable,
		bathroomAvailable,
		travelMilesCompensated,
		customButtonText,
		onCustomButtonClick,
		viewClientProfileHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{name}</StyledName>
					</StyledPersonalInfo>
					<StyledRate>
						{careType === 'live_in' && (
							<FontAwesomeIcon
								title='This client requires live in care.'
								icon={faHome}
								size='xs'
							/>
						)}
						{mealsProvided && (
							<FontAwesomeIcon title='Meals provided' icon={faUtensils} size='xs' />
						)}
						{bedroomAvailable && (
							<FontAwesomeIcon
								title='Private bedroom available'
								icon={faBed}
								size='xs'
							/>
						)}
						{bathroomAvailable && (
							<FontAwesomeIcon
								title='Private bathroom available'
								icon={faBath}
								size='xs'
							/>
						)}
						{travelMilesCompensated && (
							<FontAwesomeIcon
								title='Travel miles are compensated'
								icon={faCar}
								size='xs'
							/>
						)}
						<StyledHour>${hourlyRate}/ hr </StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				{/* <StyledLocation>Lake Raymondport</StyledLocation> */}
				<StyledDescriptionContainer>
					<StyledDescription>{jobDescription}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					{customButtonText && (
						<PrimaryButton size='small' onClick={() => onCustomButtonClick(id)}>
							{customButtonText}
						</PrimaryButton>
					)}

					<SecondaryButton size='small' onClick={() => viewClientProfileHandler(id)}>
						View
					</SecondaryButton>
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default ClientTile;
