import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_PRO_MODEL, PAYOUT_METHOD } from '../../../core/api/models';

const updateCarePro = async ({ careProId, profileInfo }) => {
	console.log(profileInfo);
	try {
		const data = await api.graph({
			query: `mutation{
				    updateProviderInfo(
					    profile_info: ${api.graphStringify(profileInfo)}
				    ) {
					    ${CARE_PRO_MODEL}

					    payoutMethods {
						    ${PAYOUT_METHOD}
					    },
				}
			}`,
		});

		return data.updateCarepro;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateCareProMutation = () => {
	return useMutation({
		mutationKey: ['updateCarePro'],
		mutationFn: (values) => {
			return updateCarePro(values);
		},
	});
};
