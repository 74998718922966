import styled from 'styled-components';

import { colors } from '../../../../../../corev2/utils';

export const StyledFieldGroup = styled.div`
	gap: 0.5rem;
	display: flex;
	flex-direction: column;
`;

export const StyledTextLabel = styled.label`
	color: ${colors.liveIns.labelColor};
`;

export const StyledAsterisk = styled.span`
	color: ${colors.warning};
`;

export const StyledTextInput = styled.input`
	border: 1px solid black;
	border-radius: 8px;
	box-shadow: none;
	color: ${colors.black};
	line-height: 40px;
	padding: 8px 12px 0px 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin solid black;
	}
`;
