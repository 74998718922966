import moment from 'moment';

export const getShiftCategories = (shifts) => {
	// Get the current time and 24 hours from now
	const now = moment();
	const next24Hours = moment().add(24, 'hours');

	// 1. Get the active shift where status is clocked_in or clocked_out
	const activeShift = shifts.find(
		(shift) => shift.status === 'clocked_in' || shift.status === 'clocked_out'
	);

	// 2. Get upcoming shifts with status 'assigned' within the next 24 hours
	const upcomingShifts = shifts.filter((shift) => {
		const startDateTime = moment(shift.start_time);
		return shift.status === 'assigned' && startDateTime.isBetween(now, next24Hours);
	});

	// 3. Get future shifts with status 'assigned' that start beyond the next 24 hours
	const futureShifts = shifts.filter((shift) => {
		const startDateTime = moment(shift.start_time);
		return shift.status === 'assigned' && startDateTime.isAfter(next24Hours);
	});

	// 4. Get submitted shifts with status 'submitted'
	const submittedShifts = shifts.filter((shift) => shift.status === 'submitted');

	// 5. Get paid shifts with status 'paid'
	const paidShifts = shifts.filter((shift) => shift.status === 'paid');

	// 6. Get canceled shifts with status 'canceled'
	const canceledShifts = shifts.filter((shift) => shift.status === 'canceled');

	// 6. Get disputed shifts with status 'disputed'
	const disputedShifts = shifts.filter((shift) => shift.status === 'disputed');

	return {
		activeShift,
		upcomingShifts,
		futureShifts,
		submittedShifts,
		paidShifts,
		canceledShifts,
		disputedShifts,
	};
};
