import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import ratesValidationSchema from './validation';

const RatesForm = (props) => {
	const { serverError, initialValues, ratesHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => ratesHandler(values),
		validationSchema: toFormikValidationSchema(ratesValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Hourly Rate </Label>

					<Input
						name='hourly_rate'
						onChange={formik.handleChange}
						placeholder='Daily Rate'
						type='number'
						value={formik.values.hourly_rate}
					/>

					{formik.errors.hourly_rate && (
						<ErrorMessage> {formik.errors.hourly_rate} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Live In Rate </Label>

					<Input
						name='live_in_rate'
						onChange={formik.handleChange}
						placeholder='Live In Rate'
						type='number'
						value={formik.values.live_in_rate}
					/>

					{formik.errors.live_in_rate && (
						<ErrorMessage> {formik.errors.live_in_rate} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default RatesForm;
