import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LiveInsPage from '../pages/LiveInsPage';
import LiveInsSummaryPage from '../pages/LiveInsSummaryPage';

import { liveInsRoutes } from './constants';

const LiveInsRoutes = () => {
	return (
		<Switch>
			<Route path={liveInsRoutes.form(':formSlug')}>
				<LiveInsPage />
			</Route>

			<Route path={liveInsRoutes.summary}>
				<LiveInsSummaryPage />
			</Route>

			<Route path={liveInsRoutes.home}>
				<LiveInsPage />
			</Route>
		</Switch>
	);
};

export default LiveInsRoutes;
