import { useMutation } from '@tanstack/react-query';

import api from 'scripts/api';

const updateCarePro = async ({ careProId, profileInfo }) => {
	console.log(profileInfo);
	try {
		const data = await api.graph({
			query: `mutation{
				    registryUpdateCareProInfo(
                        care_pro_id: ${careProId},
					    profile_info: ${api.graphStringify(profileInfo)}
				    ) {
					    id
				}
			}`,
		});

		return data.updateCarepro;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateCareProMutation = () => {
	return useMutation({
		mutationKey: ['updateCarePro'],
		mutationFn: (values) => {
			return updateCarePro(values);
		},
	});
};
