import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../../corev2/utils';

export const StyledPhotoPage = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	width: 100%;
	padding: 15px;
	margin: auto;

	text-align: center;

	@media (${mediaQueries.tablet}) {
		width: 100%;
	}
`;

export const StyledImageContainer = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 1px solid ${colors.primary.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
`;

export const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
`;
