import styled from 'styled-components';

import { colors } from '../../../../../../corev2/utils';

export const StyledRadioInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const StyledRadioLabel = styled.label`
	color: ${colors.liveIns.labelColor};
`;

export const StyledAsterisk = styled.span`
	color: ${colors.warning};
`;

export const StyledRadioOptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const StyledRadioOptionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const StyledOption = styled.div`
	color: ${colors.liveIns.labelColor};
`;

export const StyledRadioInput = styled.input`
	border-color: ${colors.forms.formInputBorderColor};
	border-radius: 4px;
	background: ${colors.white};

	display: inline-block;
	margin: 0 5px 0 0;

	width: 16px;
	height: 16px;

	border-style: solid;
	border-width: 1px;
	box-shadow: none;
	font-size: inherit;
	outline: none;

	&:checked {
		background: ${colors.primary.primary};
	}
`;
