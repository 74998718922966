import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Loader from '../../../corev2/Loader';
import NotificationContainer from './components/NotificationsContainer';
import NavBar from './components/NavBar';
import Wrapper from './components/Wrapper';

import AppRoutes from './routes';

import { authRoutes } from '../auth/routes/constants';

import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';

const CareProAppContent = () => {
	const history = useHistory();

	const {
		appIsLoading,
		isAuthenticated,
		authCarePro,
		statusUpdate,
		completedDocumentApproval,
		stripeStatus,
		hasCompletedProfileOnboarding,
	} = useAuth();

	/**
	 *  Based on the auth context of the care pro, make all redirects here.
	 *  Please restrict yourself to making all redirects happen in the App.jsx file
	 */

	useEffect(() => {
		if (isAuthenticated && authCarePro) {
			if (
				!hasCompletedProfileOnboarding ||
				stripeStatus?.status === 'unverified' ||
				!completedDocumentApproval
			) {
				history.push(authRoutes.onboarding.home);
			}
		}
	}, [isAuthenticated]);

	if (appIsLoading) {
		return <Loader />;
	}

	return (
		<>
			<NavBar statusUpdate={statusUpdate} />
			<Wrapper>
				<AppRoutes />
			</Wrapper>
		</>
	);
};

const CareProApp = () => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<NotificationContainer />
			<AuthProvider>
				<CareProAppContent />
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default CareProApp;
