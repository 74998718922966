import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const handleCompletedLiveInsWizard = async ({ carePlanId }) => {
	try {
		const data = await api.graph({
			query: `mutation{
            handleCompletedLiveInsWizard(
                care_plan_id: ${carePlanId}
            )
				{
                  id
				  completed_platform_onboard

				  carePlanSet {
				  	id
				  }

				  careNeedsSet {
				 	id
				  }
                }
            }`,
		});

		return data.handleCompletedLiveInsWizard;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useHandleCompletedLiveInsWizard = () => {
	return useMutation({
		mutationKey: ['handleCompletedLiveInsWizard'],
		mutationFn: (values) => {
			return handleCompletedLiveInsWizard(values);
		},
	});
};
