import React, { useState, useEffect } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PrimaryButton, DangerButton } from '../../../../../corev2/Buttons';
import Loader from '../../../../../corev2/Loader';
import EditDetailsForm from '../../components/EditDetailsForm';
import PaymentMethodForm from '../../components/PaymentMethodForm';
import PaymentMethodCard from '../../components/PaymentMethodCard';
import PaymentMethodBank from '../../components/PaymentMethodBank';

import {
	StyledContainer,
	StyledWelcomeSection,
	StyledWelcomeHeading,
	StyledWelcomeText,
	StyledContentWrapper,
	StyledAccountSection,
	StyledInnerAccountSection,
	StyledPaymentSection,
	StyledInnerPaymentSection,
	StyledSectionTitle,
	StyledButtonsContainer,
	StyledPaymentMethodsContainer,
	StyledPaymentInfoText,
} from './styles.js';

import { useFetchUserSavedPaymentMethodsQuery } from '../../api/queries/useFetchUserSavedPaymentMethodsQuery';
import { useUpdateUserMutation } from '../../api/mutations/useUpdateUserMutation';
import { useDeletePaymentMethodMutation } from '../../api/mutations/useDeletePaymentMethodMutation';
import { useUpdateDefaultPaymentMethodMutation } from '../../api/mutations/useUpdateDefaultPaymentMethodMutation';
import { useUpdateUserStripeTokenMutation } from '../../api/mutations/useUpdateUserStripeTokenMutation';
import { useCreateFinancialConnectionSessionQuery } from '../../api/queries/useCreateFinancialConnectionSessionQuery';
import { useCreateSetupIntentMutation } from '../../api/mutations/useCreateSetupIntentMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { useAuth } from '../../../core/hooks/useAuth';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const DashboardPageContent = () => {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [serverError, setServerError] = useState('');

	const stripe = useStripe();

	const { authUser, refreshAuthUser, logoutUser } = useAuth();

	const {
		data,
		isLoading: isLoadingPaymentMethods,
		refetch: refreshUserData,
	} = useFetchUserSavedPaymentMethodsQuery();

	const updateUser = useUpdateUserMutation();
	const deletePaymentMethod = useDeletePaymentMethodMutation();
	const updateDefaultPaymentMethod = useUpdateDefaultPaymentMethodMutation();
	const updateUserStripeToken = useUpdateUserStripeTokenMutation();
	const createSetupIntent = useCreateSetupIntentMutation();
	const useCreateFinancialConnectionSession = useCreateFinancialConnectionSessionQuery();

	useEffect(() => {
		if (data) setPaymentMethods(data.payment_methods);
	}, [data]);

	const createFinancialConnectionHandler = async () => {
		try {
			const clientSecret = await createSetupIntent.mutateAsync();

			const stripeResponse = await stripe.collectBankAccountForSetup({
				clientSecret: clientSecret,
				params: {
					payment_method_type: 'us_bank_account',
					payment_method_data: {
						billing_details: {
							name: authUser.name,
							email: authUser.email,
						},
					},
				},
				expand: ['payment_method'],
			});

			const usBankAccountConfirmation = await stripe.confirmUsBankAccountSetup(clientSecret);

			refreshUserData();
		} catch (error) {
			console.log('Error creating financial connection', error);
		}
	};

	const updateUserhandler = async (values) => {
		try {
			await updateUser.mutateAsync({
				userId: authUser.id,
				userInputs: { email: values.email, phone_number: values.phone },
			});

			refreshUserData();
			refreshAuthUser();
			Notification(notifications.dashboard.accountInfo.updated);
		} catch (error) {
			console.error('Updating the user failed', error);
			set;
		}
	};

	const addPaymentMethodHandler = async (tokenId) => {
		try {
			await updateUserStripeToken.mutateAsync({
				userId: authUser.id,
				tokenId,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodAdded);
			refreshUserData();
			refreshAuthUser();
		} catch (error) {
			console.error('Add payment method handler failed.', error);
		}
	};

	const updateDefaultPaymentMethodHandler = async (sourceId) => {
		try {
			await updateDefaultPaymentMethod.mutateAsync({
				sourceId,
			});

			Notification(notifications.dashboard.paymentMethods.defaultPaymentMethodChanged);
			refreshUserData();
			refreshAuthUser();
		} catch (error) {
			console.error('Updating default payment method failed', error);
		}
	};

	const deletePaymentMethodHandler = async (sourceId) => {
		try {
			await deletePaymentMethod.mutateAsync({
				sourceId,
				userId: authUser.id,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodDeleted);
			refreshUserData();
			refreshAuthUser();
		} catch (error) {
			console.error('Deleting payment method failed', error);
		}
	};

	const logoutHandler = () => {
		logoutUser();
		Notification(notifications.auth.logout);
	};

	if (isLoadingPaymentMethods) return <Loader />;

	return (
		<StyledContainer>
			<StyledWelcomeSection>
				<StyledWelcomeHeading>WELCOME BACK</StyledWelcomeHeading>
				<StyledWelcomeText>
					This is the Settings section for patients such as yourself
				</StyledWelcomeText>
			</StyledWelcomeSection>

			<StyledContentWrapper>
				<StyledAccountSection>
					<StyledSectionTitle>ACCOUNT</StyledSectionTitle>
					<StyledInnerAccountSection>
						<EditDetailsForm
							initialValues={{ email: authUser.email, phone: authUser.phone_number }}
							updateUserhandler={updateUserhandler}
							serverError={serverError}
						/>

						<StyledButtonsContainer>
							<PrimaryButton size='small'>Change Password</PrimaryButton>
							<PrimaryButton size='small'>Export Invoices</PrimaryButton>
							<DangerButton size='small' onClick={logoutHandler}>
								Log Out
							</DangerButton>
						</StyledButtonsContainer>
					</StyledInnerAccountSection>
				</StyledAccountSection>

				<StyledPaymentSection>
					<StyledSectionTitle>PAYMENT METHODS</StyledSectionTitle>
					<StyledInnerPaymentSection>
						<StyledPaymentInfoText>
							In order to provide our best service we must have a card on file. Please
							add your card information below.
						</StyledPaymentInfoText>

						<StyledPaymentMethodsContainer>
							{paymentMethods.map((paymentMethod) => {
								if (paymentMethod.type === 'card') {
									return (
										<PaymentMethodCard
											id={paymentMethod.id}
											key={paymentMethod.id}
											last4={paymentMethod.card.last_4}
											expiryMonth={paymentMethod.card.exp_month}
											expiryYear={paymentMethod.card.exp_year}
											cardBrand={paymentMethod.card.brand}
											isDefault={paymentMethod.default_method}
											updateDefaultPaymentMethodHandler={
												updateDefaultPaymentMethodHandler
											}
											deletePaymentMethodHandler={deletePaymentMethodHandler}
										/>
									);
								}

								if (paymentMethod.type === 'bank') {
									return (
										<PaymentMethodBank
											key={paymentMethod.id}
											id={paymentMethod.id}
											last4={paymentMethod.bank.last_4}
											bankName={paymentMethod.bank.bank_name}
											isDefault={paymentMethod.default_method}
											updateDefaultPaymentMethodHandler={
												updateDefaultPaymentMethodHandler
											}
											deletePaymentMethodHandler={deletePaymentMethodHandler}
										/>
									);
								}
							})}
						</StyledPaymentMethodsContainer>

						<PaymentMethodForm
							addPaymentMethodHandler={addPaymentMethodHandler}
							createFinancialConnectionHandler={createFinancialConnectionHandler}
						/>
					</StyledInnerPaymentSection>
				</StyledPaymentSection>
			</StyledContentWrapper>
		</StyledContainer>
	);
};

const DashboardPage = () => {
	return (
		<Elements stripe={stripePromise}>
			<DashboardPageContent />
		</Elements>
	);
};

export default DashboardPage;
