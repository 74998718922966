import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { authRoutes } from '../../routes/constants';

export const ProfileOnboardingContext = createContext();

const initialProfileOnboardingState = {
	rates: {
		name: 'rates',
		label: 'Rates',
		isCompleted: false,
		data: {
			hourlyRate: '',
			liveInRate: '',
		},
		route: authRoutes.onboarding.profile.rates,
	},
	skills: {
		name: 'skills',
		label: 'Skills',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.profile.skills,
	},
	photo: {
		name: 'photo',
		label: 'Photo',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.profile.photo,
	},
	professionalStatement: {
		name: 'professionalStatement',
		label: 'Professional Statement',
		isCompleted: false,
		data: {
			professionalStatement: '',
		},
		route: authRoutes.onboarding.profile.professionalStatement,
	},
};

export const ProfileOnboardingProvider = ({ children }) => {
	const [profileOnboardingState, setProfileOnboardingState] = useState(
		initialProfileOnboardingState
	);

	useEffect(() => {
		const localProfileOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_CARE_PRO_PROFILE_OBOARDING_STATE
		);

		if (localProfileOnboardingState) {
			setProfileOnboardingState(localProfileOnboardingState);
		}
	}, []);

	const mutateProfileOnboardingState = (key, value) => {
		setProfileOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_CARE_PRO_PROFILE_OBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetProfileOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_PROFILE_OBOARDING_STATE);
		setProfileOnboardingState(initialProfileOnboardingState);
	};

	const value = {
		profileOnboardingState,
		mutateProfileOnboardingState,
		resetProfileOnboardingState,
	};

	return (
		<ProfileOnboardingContext.Provider value={value}>
			{children}
		</ProfileOnboardingContext.Provider>
	);
};
