import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const cancelShift = async ({ shiftId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					cancelShiftByClient (shift_id: ${shiftId}) {
						${SHIFT_MODEL}
					}
				}`,
		});

		return data.cancelShiftByClient;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useCancelShiftMutation = () => {
	return useMutation({
		mutationKey: ['cancelShift'],
		mutationFn: (values) => {
			return cancelShift(values);
		},
	});
};
