import moment from 'moment';

import { colors } from '../../../../corev2/utils';

export const calendarEventStyleGenerator = (event) => {
	let style = {};

	const { shift } = event.resource;

	// Check if the event start time is in the past
	const isPastEvent = moment(event.start).isBefore(moment());
	const opacity =
		isPastEvent &&
		shift.status !== 'paid' &&
		shift.status !== 'submitted' &&
		shift.status !== 'disputed'
			? 0.2
			: 1;

	switch (shift.status) {
		case 'pending':
			style = {
				backgroundColor: colors.primary.primary,
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'assigned':
			style = {
				backgroundColor: '#2196f3', // Orange color for assigned shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'clocked_in':
			style = {
				backgroundColor: colors.secondary.secondary, // Blue color for clocked-in shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'clocked_out':
			style = {
				backgroundColor: '#9e9e9e', // Gray color for clocked-out shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'submitted':
			style = {
				backgroundColor: '#673ab7', // Purple color for submitted shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'disputed':
			style = {
				backgroundColor: '#e53935', // Red color for canceled shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'canceled':
			style = {
				backgroundColor: '#ff7043', // Red orange color for canceled shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'paid':
			style = {
				backgroundColor: '#ffd600', // Yellow color for paid shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		default:
			style = {
				backgroundColor: colors.primary.primary,
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
	}

	return { style };
};
