import React from 'react';

import ShiftNote from './ShiftNote';

import { StyledTitle, StyledTextArea, StyledShiftFormContainer } from './styles';

import { useShiftContext } from '../../context/ShiftContext';

const ShiftNotes = () => {
	const { shift } = useShiftContext();

	return (
		<StyledShiftFormContainer>
			<StyledTitle>Notes</StyledTitle>
			<ShiftNote>
				<StyledTextArea value={shift.notes} name='notes' label='' disabled />
			</ShiftNote>
		</StyledShiftFormContainer>
	);
};

export default ShiftNotes;
