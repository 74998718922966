import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledLiveInsSummaryPage = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1330px;
	margin: auto;
	gap: 20px;
	padding: 0 1rem;
	text-align: left;

	@media ${mediaQueries.tablet} {
		padding: 0rem;
	}
`;

export const StyledHeadingContainer = styled.div`
	width: fit-content;
	display: flex;
`;
