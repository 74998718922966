import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const verifyUserPhone = async ({ phone, email }) => {
	try {
		const data = await api.graph({
			query: `mutation {
				verifyUserPhone(phone_number: "${phone}", email: "${email}")
			}`,
		});

		return data.verifyUserPhone;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useVerifyUserPhoneMutation = () => {
	return useMutation({
		mutationKey: ['verifyUserPhone'],
		mutationFn: (values) => {
			return verifyUserPhone(values);
		},
	});
};
