import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { FORM_MODEL } from '../../../core/api/models';

const updateCarePlanFormAnswers = async ({ formId, answers }) => {
	try {
		const data = await api.graph({
			query: `mutation {
            updateCarePlanFormAnswers ( 
                form_data_id: ${formId}, 
                form_answers: ${api.graphStringify(answers)}
                ) {
                ${FORM_MODEL}
                }
            }`,
		});

		return data.updateCarePlanFormAnswers;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useUpdateCarePlanFormAnswersMutation = () => {
	return useMutation({
		mutationKey: ['updateCarePlanFormAnswers'],
		mutationFn: (values) => {
			return updateCarePlanFormAnswers(values);
		},
	});
};
