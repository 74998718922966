import { useMutation } from '@tanstack/react-query';

import api from '../../core/utility/graphQL';

import { FORM_MODEL } from './useFetchCareProSkills';

const updateCareProFormAnswers = async ({ formId, answers }) => {
	try {
		const data = await api.graph({
			query: `mutation {
        updateCareProFormAnswers ( 
          form_data_id: ${formId}, 
          form_answers: ${api.graphStringify(answers)}
        ) {
          ${FORM_MODEL}
        }
      }`,
		});

		return data.updateCareProFormAnswers;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

const useUpdateCareProFormAnswers = () => {
	return useMutation({
		mutationKey: ['updateCareProFormAnswers'],
		mutationFn: (values) => {
			return updateCareProFormAnswers(values);
		},
	});
};

export default useUpdateCareProFormAnswers;
