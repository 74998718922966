import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../../core/components/PageHeading';
import RelationshipForm from '../../../components/RelationshipForm';

import { useRelationshipMutation } from '../../../api/mutations/useRelationshipMutation';
import { useCarePlan } from '../../../hooks/useCarePlan';
import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledRelationshipPage } from './styles';
import { carePlanRoutes } from '../../../routes/constants';

const RelationshipPage = () => {
	const [serverError, setServerError] = useState('');

	const relationshipMutation = useRelationshipMutation();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser, modifyAuthUser } = useAuth();
	const history = useHistory();

	const relationshipHandler = async (values) => {
		try {
			const data = await relationshipMutation.mutateAsync({
				userId: authUser.id,
				careType: values.careType,
				cc_first_name: values.cc_first_name,
				cc_last_name: values.cc_last_name,
				cc_phone_number: values.cc_phone_number,
				cc_relation_to_cr: values.cc_relation_to_cr,
			});

			modifyAuthUser('carePlan', data);

			mutateCarePlanState('careCoordinator', {
				...carePlanState.careCoordinator,
				data: {
					relationship: values.relationship,
				},
				isCompleted: true,
			});

			history.push(carePlanRoutes.onboarding.info);
		} catch (error) {
			setServerError(error[0].message);
			console.error('Creating a care plan failed', error);
		}
	};

	const renderInitialValues = () => {
		if (authUser.carePlan) {
			return {
				cc_relation_to_cr: authUser.carePlan.cc_relation_to_cr,
				cc_first_name: authUser.carePlan.cc_first_name,
				cc_last_name: authUser.carePlan.cc_last_name,
				cc_phone_number: authUser.carePlan.cc_phone_number,
				careType: authUser.carePlan.care_type,
			};
		}

		return {
			relationship: '',
			cc_relation_to_cr: '',
			cc_first_name: '',
			cc_last_name: '',
			careType: '',
		};
	};
	return (
		<StyledRelationshipPage>
			<PageHeading>Care Coordinator</PageHeading>
			<RelationshipForm
				initialValues={renderInitialValues()}
				relationshipHandler={relationshipHandler}
				serverError={serverError}
			/>
		</StyledRelationshipPage>
	);
};

export default RelationshipPage;
