import React, { useState, createContext, useEffect } from 'react';

import { useAuth } from '../../../core/hooks/useAuth';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

export const LiveInsContext = createContext();

const intiialLiveInsState = {};

export const LiveInsProvider = ({ children }) => {
	const [liveInsState, setLiveInsState] = useState(intiialLiveInsState);

	const { authUser } = useAuth();

	useEffect(() => {
		const { carePlan } = authUser;

		if (carePlan) {
			const liveInsData = carePlan.liveInCare;
			const { form_answers } = liveInsData;
			const { sections } = liveInsData.trait_template;

			console.log(form_answers, sections);

			const newLiveInsState = sections.reduce((acc, section) => {
				const { slug, traits } = section;
				acc[slug] = form_answers
					.filter((answer) => traits.some((trait) => trait.id === answer.trait_id))
					.map((formAnswer) => {
						const trait = traits.find((trait) => trait.id === formAnswer.trait_id);

						return {
							...trait,
							trait_value_list: formAnswer.trait_value,
						};
					});
				return acc;
			}, {});

			setLiveInsState(newLiveInsState);
			setInLocalStorage(localStorageKeys.FYI_LIVE_INS_STATE, JSON.stringify(newLiveInsState));
		}

		const liveInsState = getFromLocalStorage(localStorageKeys.FYI_LIVE_INS_STATE);

		if (liveInsState) {
			setLiveInsState(liveInsState);
		}
	}, [authUser]);

	const mutateLiveInsState = (key, value) => {
		setInLocalStorage(
			localStorageKeys.FYI_LIVE_INS_STATE,
			JSON.stringify({
				...liveInsState,
				[key]: {
					...liveInsState[key],
					...value,
				},
			})
		);

		setLiveInsState((prevState) => {
			return {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};
		});
	};

	const resetLiveInsState = () => {
		setLiveInsState(intiialLiveInsState);
	};

	const value = {
		liveInsState,
		mutateLiveInsState,
		resetLiveInsState,
	};

	return <LiveInsContext.Provider value={value}>{children}</LiveInsContext.Provider>;
};
