export const authRoutes = {
	login: '/care-pro/auth/login',
	passwordReset: '/care-pro/auth/reset-password',
	onboarding: {
		home: '/care-pro/auth/onboarding',
		platform: {
			home: '/care-pro/auth/onboarding/platform',
			personalInfo: '/care-pro/auth/onboarding/platform/personal',
			accountInfo: '/care-pro/auth/onboarding/platform/account',
			authCode: '/care-pro/auth/onboarding/platform/verify',
		},
		profile: {
			home: '/care-pro/auth/onboarding/profile',
			rates: '/care-pro/auth/onboarding/profile/rates',
			skills: '/care-pro/auth/onboarding/profile/skills',
			photo: '/care-pro/auth/onboarding/profile/photo',
			professionalStatement: '/care-pro/auth/onboarding/profile/professional-statement',
		},
		stripe: {
			home: '/care-pro/auth/onboarding/stripe',
			address: '/care-pro/auth/onboarding/stripe/address',
			payouts: '/care-pro/auth/onboarding/stripe/payouts',
			personal: '/care-pro/auth/onboarding/stripe/personal',
			tos: '/care-pro/auth/onboarding/stripe/tos',
		},
	},
};
