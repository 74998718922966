import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { DangerButton, TransparentButton } from '../../../../../corev2/Buttons';
import { FieldGroup, TextArea, ErrorMessage } from '../../../../../corev2/Forms';
import Text from '../../../../../corev2/Text';

import Modal from '../../../core/components/Modal';
import ShiftItem from '../../../core/components/ShiftItem';

import { disputeShiftFormSchema } from './validation';

import { StyledFormContainer, StyledFormTitle, StyledButtonsContainer } from './styles';

const DisputeShiftForm = (props) => {
	const { shift, carePro, disputeShiftHandler, disputeShiftModalHandler } = props;

	const formik = useFormik({
		initialValues: {
			disputeReason: '',
		},
		validationSchema: toFormikValidationSchema(disputeShiftFormSchema),
		onSubmit: (values) => {
			const { disputeReason } = values;

			disputeShiftHandler(disputeReason, shift.id);
		},
	});

	return (
		<Modal>
			<StyledFormContainer>
				<StyledFormTitle>Dispute Shift</StyledFormTitle>

				<ShiftItem isAvailable={true} shift={shift} />

				<FieldGroup>
					<TextArea
						name='disputeReason'
						placeholder='Enter a reason for disputing this shift'
						values={formik.values.disputeReason}
						onChange={formik.handleChange}
					/>

					{formik.errors.disputeReason && (
						<ErrorMessage>{formik.errors.disputeReason}</ErrorMessage>
					)}
				</FieldGroup>

				<Text size='extraLarge'>
					This action is irreversable. Once this shift is disputed, the Care Pro will be
					notified and your registry will review the reasons for dispute.
				</Text>

				<StyledButtonsContainer>
					<DangerButton type='submit' size='small' onClick={formik.handleSubmit}>
						Dispute Shift
					</DangerButton>

					<TransparentButton size='small' onClick={disputeShiftModalHandler}>
						Close
					</TransparentButton>
				</StyledButtonsContainer>
			</StyledFormContainer>
		</Modal>
	);
};

export default DisputeShiftForm;
