import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from '../pages/LoginPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import PlatformOnboardingPage, {
	PersonalInfoPage,
	AccountInfoPage,
	AuthCodePage,
} from '../pages/PlatformOnboardingPage';
import ProfileOnboardingPage, {
	Ratespage,
	SkillsPage,
	Photopage,
	ProfessionalStatementPage,
} from '../pages/ProfileOnboardingPage';
import StripeOnboardingPage, {
	AddressPage,
	PayoutsPage,
	PersonalInfoPage as StripePersonalInfoPage,
	TOSPage,
} from '../pages/StripeOnboardingPage';
import OnboardingWelcomePage from '../pages/OnboardingWelcomePage';

import { authRoutes } from './constants';

const AuthRoutes = () => {
	return (
		<Switch>
			<Route path={authRoutes.onboarding.home} exact>
				<OnboardingWelcomePage />
			</Route>

			<Route path={authRoutes.login}>
				<LoginPage />
			</Route>

			<Route path={authRoutes.passwordReset}>
				<PasswordResetPage />
			</Route>

			<Route path={authRoutes.onboarding.platform.home}>
				<PlatformOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.platform.personalInfo}>
							<PersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.accountInfo}>
							<AccountInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.authCode}>
							<AuthCodePage />
						</Route>
					</Switch>
				</PlatformOnboardingPage>
			</Route>

			<Route path={authRoutes.onboarding.profile.home}>
				<ProfileOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.profile.rates}>
							<Ratespage />
						</Route>

						<Route path={authRoutes.onboarding.profile.skills}>
							<SkillsPage />
						</Route>

						<Route path={authRoutes.onboarding.profile.photo}>
							<Photopage />
						</Route>

						<Route path={authRoutes.onboarding.profile.professionalStatement}>
							<ProfessionalStatementPage />
						</Route>
					</Switch>
				</ProfileOnboardingPage>
			</Route>

			<Route path={authRoutes.onboarding.stripe.home}>
				<StripeOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.stripe.address}>
							<AddressPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.payouts}>
							<PayoutsPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.personal}>
							<StripePersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.tos}>
							<TOSPage />
						</Route>
					</Switch>
				</StripeOnboardingPage>
			</Route>
		</Switch>
	);
};

export default AuthRoutes;
