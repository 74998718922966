import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { NAVIGATION, THEME } from 'constants';

import {
	toggleAdminOnboardOverlay,
	logoutAdmin,
	checkAdminSession,
	receiveUserDetails,
} from 'reducers/admin';

import AdminRoutes from './AdminRoutes';
import Text from '../Text';

import AdminWelcome from './AdminWelcome';
import AdminHomeLayout from './AdminHomeLayout';

const AdminApp = (props) => {
	const {
		admin,
		toggleAdminOnboardOverlay,
		logoutAdmin,
		receiveUserDetails,
		checkAdminSession,
		location,
		history,
		history: { push },
	} = props;

	useEffect(() => {
		checkAdminSession();
	}, []);

	return (
		<Route
			path='/registry'
			children={(props) => {
				return (
					<AdminHomeLayout
						THEME={THEME.admin}
						NAVIGATION={NAVIGATION.admin}
						loggedIn={admin !== null}
						push={push}
						logOut={logoutAdmin}
						receiveUserDetails={receiveUserDetails}
						location={location}
						history={history}
						admin={admin}
					>
						{admin ? (
							<AdminRoutes {...props} />
						) : (
							<AdminWelcome
								toggleAdminOnboardOverlay={() => toggleAdminOnboardOverlay(true)}
								{...props}
							/>
						)}
					</AdminHomeLayout>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ admin }, props) => {
	return {
		admin: admin.admin,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		toggleAdminOnboardOverlay: (status) => dispatch(toggleAdminOnboardOverlay(status)),
		checkAdminSession: () => dispatch(checkAdminSession()),
		logoutAdmin: () => dispatch(logoutAdmin()),
		receiveUserDetails: (parms) => dispatch(receiveUserDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);
