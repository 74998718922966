import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import { PrimaryButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import UploadPhotoForm from '../../../components/UploadPhotoForm';

import { useUpdateCareProInfoMutation } from '../../../api/mutations/useUpdateCareProInfoMutation';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useProfileOnboarding } from '../../../hooks/useProfileOnboarding';

import { authRoutes } from '../../../routes/constants';

import uploadFilesToAWSS3 from '../../../../core/utility/uploadFilesToAWSS3';

import { StyledPhotoPage, StyledImageContainer, StyledImage } from './styles';

const PhotoPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { authCarePro, refreshAuthCarePro } = useAuth();
	const updateCareProInfo = useUpdateCareProInfoMutation();

	const { profileOnboardingState, mutateProfileOnboardingState, resetProfileOnboardingState } =
		useProfileOnboarding();

	const handleUploadDocuments = async (file) => {
		try {
			// Upload the file to AWS S3
			setIsLoading(true);
			const uploadedFile = await uploadFilesToAWSS3({ [authCarePro.id]: { file } });

			const updatedCarePro = await updateCareProInfo.mutateAsync({
				image_url: uploadedFile[authCarePro.id].url,
			});

			refreshAuthCarePro();
			setIsLoading(false);
		} catch (error) {
			console.error('Photo upload failed:', error);
		}
	};

	const handleClearImage = async () => {
		try {
			setIsLoading(true);
			const updatedCarePro = await updateCareProInfo.mutateAsync({
				image_url: '',
			});

			refreshAuthCarePro();
			setIsLoading(false);
		} catch (error) {
			console.error('Photo clear failed:', error);
		}
	};

	const goToProfessionalStatementPage = () => {
		mutateProfileOnboardingState('photo', {
			...profileOnboardingState.photo,
			isCompleted: true,
		});

		history.push(authRoutes.onboarding.profile.professionalStatement);
	};

	return (
		<StyledPhotoPage>
			<PageHeading>Upload Photo</PageHeading>

			<StyledImageContainer>
				<StyledImage src={authCarePro.image_url}></StyledImage>
			</StyledImageContainer>

			<UploadPhotoForm
				onUpload={handleUploadDocuments}
				onClearImage={handleClearImage}
				isLoading={isLoading}
				serverError={serverError}
			/>

			{authCarePro.image_url && (
				<PrimaryButton onClick={goToProfessionalStatementPage}>Next</PrimaryButton>
			)}
		</StyledPhotoPage>
	);
};

export default PhotoPage;
