import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
	StyledPaymentMethodWrapper,
	StyledPaymentMethod,
	StyledCardNumber,
	StyledExpiryDate,
	StyledDeleteButton,
} from './styles';

const PaymentMethodCard = ({
	id,
	bankName,
	last4,
	isDefault,
	updateDefaultPaymentMethodHandler,
	deletePaymentMethodHandler,
}) => {
	const [showDeleteButton, setShowDeleteButton] = useState(false);

	const showDeleteButtonHandler = () => setShowDeleteButton(true);
	const hideDeleteButtonHandler = () => setShowDeleteButton(false);

	return (
		<StyledPaymentMethodWrapper
			onMouseEnter={showDeleteButtonHandler}
			onMouseLeave={hideDeleteButtonHandler}
		>
			<StyledPaymentMethod
				isDefault={isDefault}
				onClick={() => updateDefaultPaymentMethodHandler(id)}
			>
				<StyledCardNumber>xxxx-xxxx-xxxx-{last4}</StyledCardNumber>
				<StyledExpiryDate>{bankName}</StyledExpiryDate>
				<StyledDeleteButton
					showDeleteButton={showDeleteButton}
					onClick={() => deletePaymentMethodHandler(id)}
				>
					<FontAwesomeIcon icon={faTrash} size='sm' />
				</StyledDeleteButton>
			</StyledPaymentMethod>
		</StyledPaymentMethodWrapper>
	);
};

export default PaymentMethodCard;
