import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const fetchShift = async ({ shiftId }) => {
	try {
		const data = await api.graph({
			query: `{
            fetchShift(shift_id: ${shiftId}) {
                    ${SHIFT_MODEL}
                }
            }`,
		});

		return data.fetchShift;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchShiftQuery = (values) => {
	return useQuery({
		queryKey: ['fetchShift'],
		queryFn: () => {
			return fetchShift(values);
		},
	});
};
