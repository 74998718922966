import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledUploadPhotoForm = styled.div`
	background: #f0f8fd;
	border: 1px solid #cbd4db;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	width: 100%;

	border: 1px solid rgba(43, 105, 166, 0.3);
	position: relative;
`;

export const StyledDocumentTitle = styled.h3`
	margin: 0;
	font-size: 1.1em;
	line-height: 1.4;
	letter-spacing: 2px;
	color: ${colors.primary.primary};
`;

export const StyledDocumentUploadArea = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 4px dashed rgba(43, 105, 166, 0.3);

	background-color: #fff;
	border-radius: 8px;
	cursor: pointer;
	color: #1e5a8e;
	font-size: 16px;
	text-align: center;
`;

export const StyledUploadFilesText = styled.span``;

export const StyledFileInput = styled.input`
	display: none;
`;

export const StypedExpirationLabel = styled.label`
	margin-bottom: 5px;
	font-size: 14px;
	color: #6c7a89;
`;

export const StyledClearFileIcon = styled.span`
	cursor: pointer;
	font-size: 16px;
	color: ${colors.primary.primary};
	position: absolute;
	right: 10px;
	top: 15px;
	transform: translateY(-50%);
	&:hover {
		color: font-size: 20px;
	}
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	margin-top: 1rem;
	width: 100%;

	& > button {
		width: 100%;
	}
`;
