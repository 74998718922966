import React from 'react';

import {
	StyledSelectInputContainer,
	StyledSelectLabel,
	StyledAsterisk,
	StyledSelectOptionsContainer,
	StyledSelectOptionsWrapper,
	StyledOption,
	StyledSelectInput,
} from './styles';

const Select = (props) => {
	const { label, options, isRequired, isChecked, onChange } = props;

	return (
		<StyledSelectInputContainer>
			<StyledSelectLabel>
				{label} {isRequired && <StyledAsterisk>*</StyledAsterisk>}
			</StyledSelectLabel>
			<StyledSelectOptionsContainer>
				<StyledSelectOptionsWrapper>
					<StyledSelectInput type='select' onChange={onChange}>
						{options.map((option) => (
							<StyledOption key={option}>{option}</StyledOption>
						))}
					</StyledSelectInput>
				</StyledSelectOptionsWrapper>
			</StyledSelectOptionsContainer>
		</StyledSelectInputContainer>
	);
};

export default Select;
