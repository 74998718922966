import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import BackButton from '../../../../../corev2/BackButton';
import { CareProTile, OfferTile } from '../../../../../corev2/Tiles';
import Loader from '../../../../../corev2/Loader';
import Text from '../../../../../corev2/Text';

import ShiftSelector from '../../../core/components/ShiftSelector';
import OfferModal from '../../../core/components/OfferModal';
import PageHeading from '../../../core/components/PageHeading';

import OfferAction from '../../components/OfferAction';
import OffersFilter from '../../components/OffersFilter';

import { coreRoutes } from '../../../core/routes/constants';
import { carePlanRoutes } from '../../../careplan/routes/constants';
import { offersRoutes } from '../../routes/constants';
import { jobPostsRoutes } from '../../../jobPosts/routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useOffersFilter } from '../../hooks/useOffersFilter';

import { useFetchSingleJobPostQuery } from '../../api/queries/useFetchSingleJobPostQuery';
import { useFetchCarePlanShiftsQuery } from '../../../core/api/queries/useFetchCarePlanShiftsQuery';
import { useSearchCareProsByCarePlanQuery } from '../../api/queries/useSearchCareProsByCarePlanQuery';
import { useFetchCarePlanOffersQuery } from '../../api/queries/useFetchCarePlanOffersQuery';
import { useOfferCareProMutation } from '../../api/mutations/useOfferCareProMutation';
import { useRespondToOfferMutation } from '../../api/mutations/useRespondToOfferMutation';
import { useContactCareProMutation } from '../../api/mutations/useContactCareProMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import {
	StyledOffersPage,
	StyledJobPostBanner,
	StyledCloseBannerIcon,
	StyledPageHeadingContainer,
	StyledActionSection,
	StyledActionsContainer,
	StyledCareProsContainer,
	StyledCategoryDescription,
} from './styles';

const OffersPage = (props) => {
	const [individualJobPostMode, setIndividualJobPostMode] = useState(false);
	const [isShiftSelectionModalOpen, setIsShiftSelectionModalOpen] = useState(false);
	const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
	const [selectedCarePro, setSelectedCarePro] = useState(null);
	const [selectedOffer, setSelectedOffer] = useState(null);
	const history = useHistory();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const status = searchParams.get('status') || 'care-pros';
	const jobPostId = searchParams.get('job_post_id');

	const { authUser, hasDefaultPaymentMethod } = useAuth();

	const { data: carePros, isLoading: isLoadingCarePros } = useSearchCareProsByCarePlanQuery(
		authUser.carePlan.id,
		1
	);

	const {
		data: offers,
		isLoading: isLoadingOffers,
		refetch: refetchOffers,
	} = useFetchCarePlanOffersQuery(individualJobPostMode ? jobPostId : null);

	const {
		data: jobPost,
		isLoading: isLoadingJobPost,
		refetch: updateJobPost,
	} = useFetchSingleJobPostQuery(jobPostId);

	const {
		data: shifts,
		isLoading: isLoadingShifts,
		refetch: refetchShifts,
	} = useFetchCarePlanShiftsQuery();

	useEffect(() => {
		if (jobPostId) {
			setIndividualJobPostMode(true);
		}

		return () => setIndividualJobPostMode(false);
	}, [jobPostId]);

	const { stats, filteredOffers } = useOffersFilter(carePros, offers);

	const offerCarePro = useOfferCareProMutation();
	const contactCarePro = useContactCareProMutation();
	const respondToOffer = useRespondToOfferMutation();

	const changeSearchParam = (name, status) => {
		searchParams.set('status', status);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const chatWithCareProHandler = async (careProId) => {
		try {
			const data = await contactCarePro.mutateAsync({ careProId, userId: authUser.id });

			history.push(coreRoutes.chat);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const inviteCareProToJobPostHandler = async (careProId) => {
		if (!hasDefaultPaymentMethod) {
			return Notification(notifications.auth.paymemntMethod);
		}

		if (!hasSetSchedule) {
			return Notification(notifications.jobPosts.scheduleNeeded);
		}

		try {
			await inviteCareProToJobPostMutation.mutateAsync({ jobPostId, careProId });
			updateJobPost();

			Notification(notifications.jobPosts.careProInvited);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const offerCareProHandler = async (hourlyRate, incomingShiftIds) => {
		if (!hasDefaultPaymentMethod) {
			return Notification(notifications.auth.paymemntMethod);
		}

		try {
			await offerCarePro.mutateAsync({
				jobPostId: jobPost && jobPost.id,
				careProId: selectedCarePro.id,
				hourlyRate,
				shiftIds: incomingShiftIds,
			});

			refetchOffers();
			setIsShiftSelectionModalOpen(false);
			Notification(notifications.jobPosts.careProOffered);
		} catch (error) {
			console.error('Offer care pro failed', error);
		}
	};

	const respondToOfferHandler = async (offerId, accept) => {
		try {
			await respondToOffer.mutateAsync({
				offerId,
				accept,
			});

			refetchOffers();
			setIsOfferModalOpen(false);
			Notification(notifications.jobPosts.careProOffered);
		} catch (error) {
			console.error('Responding to offer failed', error);
		}
	};

	const shiftSelectionModalHandler = (carePro) => {
		setIsShiftSelectionModalOpen(!isShiftSelectionModalOpen);
		setSelectedCarePro(isShiftSelectionModalOpen ? null : carePro);
	};

	const viewOfferModalHandler = (offer) => {
		setIsOfferModalOpen(!isOfferModalOpen);
		setSelectedOffer(isOfferModalOpen ? null : offer);
	};

	const goToViewOfferPage = async (careProId) => {
		const job = jobPost.jobs.find((job) => job.provider.id === careProId);

		history.push(jobPostsRoutes.viewOfferPage(jobPost.id, job.id));
	};

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	const goToOffersPage = () => {
		history.push({
			pathname: offersRoutes.home,
			search: '',
		});
	};

	const goToJobPostsPage = () => {
		history.push(jobPostsRoutes.home);
	};

	const goToCarePlanPage = () => {
		history.push(coreRoutes.carePlan);
	};

	const goToSchedulePage = () => {
		history.push(carePlanRoutes.schedule);
	};

	const getCategoryDescriptions = () => {
		let description;

		switch (status) {
			case 'search-care-pros':
				description = (
					<Text size='large'>
						Browse all available Care Pros in your registry. Use filters to find
						qualified candidates and send invitations directly.
					</Text>
				);
				break;

			case 'invited':
				description = (
					<Text size='large'>
						Care Pros you’ve invited to apply for the job. Monitor responses and follow
						up if needed to ensure timely hiring.
					</Text>
				);
				break;

			case 'applied':
				description = (
					<Text size='large'>
						Care Pros who have applied for your job. Review their applications,
						qualifications, and experience before proceeding with interviews or offers.
					</Text>
				);
				break;

			case 'offered':
				description = (
					<Text size='large'>
						Candidates you’ve extended job offers to. Wait for their responses or
						contact them to expedite the hiring process.
					</Text>
				);
				break;

			case 'active':
				description = (
					<Text size='large'>
						Care Pros currently working on your job. Manage assignments, monitor
						progress, and communicate for seamless job execution.
					</Text>
				);
				break;

			case 'inactive':
				description = (
					<Text size='large'>
						Care Pros who declined offers, completed assignments, or whose contracts
						ended. Review past work history for future consideration or new job
						postings.
					</Text>
				);
				break;

			default:
				description = (
					<Text size='large'>
						Manage Care Pros related to your job posts. Select a category to view Care
						Pros or create a new job post using the button below.
					</Text>
				);
		}

		return description;
	};

	if (isLoadingJobPost || isLoadingCarePros || isLoadingOffers || isLoadingShifts) {
		return <Loader />;
	}

	return (
		<StyledOffersPage>
			{individualJobPostMode && (
				<>
					<StyledJobPostBanner>
						<Text inverted>
							You're viewing all offers related to the job post "{jobPost?.title}".
							Close this banner to go to the offers page to view all offers for your
							entire care plan.
						</Text>

						<StyledCloseBannerIcon onClick={() => setIndividualJobPostMode(false)}>
							x
						</StyledCloseBannerIcon>
					</StyledJobPostBanner>
					<StyledPageHeadingContainer>
						<BackButton withText clickHandler={goToOffersPage} />
						<PageHeading>{jobPost?.title}</PageHeading>
					</StyledPageHeadingContainer>
				</>
			)}

			{isShiftSelectionModalOpen && (
				<ShiftSelector
					title={`Send offer to ${selectedCarePro.displayName}`}
					shifts={shifts}
					shiftSelectionModalHandler={shiftSelectionModalHandler}
					offerCareProHandler={offerCareProHandler}
				/>
			)}

			{isOfferModalOpen && (
				<OfferModal
					title={`Shifts Offered to ${selectedOffer.provider.display_name} at ${selectedOffer.hourly_rate}/hr`}
					offer={selectedOffer}
					viewOfferModalHandler={viewOfferModalHandler}
					respondToOfferHandler={respondToOfferHandler}
					isModificationAllowed={
						selectedOffer.status === 'offered' &&
						selectedOffer.offer_made_by === 'carePro'
					}
					isValid={selectedOffer.offered_shifts.every(
						(offeredShift) => offeredShift.shift.status === 'pending'
					)}
				/>
			)}

			<StyledActionSection>
				<StyledActionsContainer>
					<OffersFilter stats={stats} changeSearchParam={changeSearchParam} />

					<OfferAction
						title='Schedule'
						description='Manage your schedule to let CarePros know when you need care.'
						goToPage={goToSchedulePage}
					/>

					<OfferAction
						title='Care Plan'
						description='Manage your Care Plan to let CarePros know what care you need.'
						goToPage={goToCarePlanPage}
					/>
				</StyledActionsContainer>

				<StyledCareProsContainer>
					<StyledCategoryDescription>
						{getCategoryDescriptions()}
					</StyledCategoryDescription>

					{stats.carePros.isActive &&
						carePros.map((carePro) => (
							<CareProTile
								key={carePro.id}
								id={carePro.id}
								displayName={carePro.displayName}
								rating={carePro.averageReview}
								hourlyRate={carePro.hourly_rate}
								professionalStatement={carePro.professional_statement}
								gender={carePro.gender}
								careType={carePro.care_type}
								customButtonText={'Offer'}
								onCustomButtonClick={() => shiftSelectionModalHandler(carePro)}
								viewCareProHandler={viewCareProHandler}
								careProInfo={carePro}
							/>
						))}

					{!stats.carePros.isActive &&
						filteredOffers.map((offer) => {
							return (
								<OfferTile
									key={offer.id}
									carePro={offer.provider}
									hourly_rate={offer.hourly_rate}
									offer_text='Ill do it'
									customButtonText={'View Offer'}
									onCustomButtonClick={() => viewOfferModalHandler(offer)}
									isValid={offer.offered_shifts.every(
										(offeredShift) => offeredShift.shift.status === 'pending'
									)}
								/>
							);
						})}

					{stats.carePros.isActive && carePros.length === 0 && (
						<Text>No Care Pros found.</Text>
					)}

					{!stats.carePros.isActive && carePros.length === 0 && (
						<Text>No offers found.</Text>
					)}
				</StyledCareProsContainer>
			</StyledActionSection>
		</StyledOffersPage>
	);
};

export default OffersPage;
