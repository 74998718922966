import React from 'react';
import { Hearts } from 'react-loader-spinner';
import Colors from 'colors';

import { LoaderSection } from './styles';
import useFetchCareProSkills from './api/useFetchCareProSkills';
import useUpdateCareProFormAnswers from './api/useUpdateCareProFormAnswer';

import { useProfileOnboarding } from '../../../hooks/useProfileOnboarding';

import SkillsFormGen from './components/SkillFormGen';
import Text from 'AppComp/Text';
const SkillsPage = () => {
	const { data, isLoading } = useFetchCareProSkills();

	const updateCareProFormAnswers = useUpdateCareProFormAnswers();

	const { profileOnboardingState, mutateProfileOnboardingState, resetProfileOnboardingState } =
		useProfileOnboarding();

	if (isLoading) {
		return (
			<LoaderSection>
				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Hearts color={Colors.primary.primary} height={100} width={100} />
				</div>
			</LoaderSection>
		);
	}

	console.log(data);

	if (data === undefined || data === null) {
		return (
			<LoaderSection>
				<Text>Form not found</Text>
			</LoaderSection>
		);
	}

	const handleSubmit = async (values) => {
		console.log('submitting form', values);
		// do whatever you need to move forward

		// you can assume that this can be called after
		try {
			const updateVal = await updateCareProFormAnswers.mutateAsync({
				formId: data.id,
				answers: values,
			});
			console.log('here am i');
			mutateProfileOnboardingState('skills', {
				...profileOnboardingState.skills,
				isCompleted: true,
			});

			// add toast here
		} catch (err) {
			console.error(err);
		}
	};

	return <SkillsFormGen handleSubmit={handleSubmit} formData={data} />;
};

export default SkillsPage;
