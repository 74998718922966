import React from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../../../corev2/PageHeading';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import PricingCalculator from '../../../../../corev2/PricingCalculator';
import Text from '../../../../../corev2/Text';

import { LiveInsProvider } from '../../context/LiveInsContext';

import { useLiveInsContext } from '../../hooks/useLiveInsContext';

import { coreRoutes } from '../../../core/routes/constants';

import { StyledLiveInsSummaryPage, StyledHeadingContainer } from './styles';

import liveInsKeys from '../../../core/constants/liveInsKeys';

const LivesInsContent = () => {
	const history = useHistory();

	const { liveInsState } = useLiveInsContext();

	if (Object.values(liveInsState).length === 0) {
		return <StyledLiveInsSummaryPage>No live-in data available.</StyledLiveInsSummaryPage>;
	}

	const formattedTexts = [];

	Object.values(liveInsState).forEach((category) => {
		Object.values(category).forEach((item) => {
			const { live_in_key_look_up, trait_type, trait_value_list } = item;

			if (!trait_value_list || trait_value_list.length === 0) return;

			const value = JSON.parse(trait_value_list); // Get first value in array

			switch (live_in_key_look_up) {
				case liveInsKeys.number_of_break_hours:
					formattedTexts.push(
						`I agree to ${parseFloat(value).toFixed(2)} hour(s) of break(s).`
					);
					break;
				case liveInsKeys.number_of_sleep_hours:
					formattedTexts.push(
						`I agree to ${parseFloat(value).toFixed(2)} hour(s) of sleep time.`
					);
					break;
				case liveInsKeys.below_minimum_rest_time_acknowledgment:
					console.log(value);
					if (value === 'true') {
						formattedTexts.push(
							'I acknowledge that I am providing less than 8 hours of rest time.'
						);
					}
					break;
				case liveInsKeys.daily_rate:
					formattedTexts.push(
						`I agree to pay $${parseFloat(value).toFixed(
							2
						)} per hour for this 24-hour period.`
					);
					break;
				case liveInsKeys.below_minimum_wage_acknowledgment:
					console.log(value);
					if (value === 'true') {
						formattedTexts.push(
							'I acknowledge that I am paying less than the minimum wage in my state.'
						);
					}
					break;
				case liveInsKeys.number_of_days_of_care:
					formattedTexts.push(`I require ${parseFloat(value).toFixed(2)} days of care.`);
					break;
				case liveInsKeys.number_of_care_pros_required:
					formattedTexts.push(
						`I would like to hire ${parseFloat(value).toFixed(2)} Care Provider(s).`
					);
					break;
				case liveInsKeys.private_bedroom_availalable:
					formattedTexts.push(
						value === 'on'
							? 'I will provide a private bedroom.'
							: 'I will not provide a private bedroom.'
					);
					break;
				case liveInsKeys.private_bathroom_available:
					formattedTexts.push(
						value === 'on'
							? 'I will provide a private bathroom.'
							: 'I will not provide a private bathroom.'
					);
					break;
				case liveInsKeys.travel_miles_compensated:
					formattedTexts.push(
						value === 'on'
							? 'Miles will be compensated.'
							: 'Miles will not be compensated.'
					);
					break;
				case liveInsKeys.meals_provided:
					formattedTexts.push(
						value === 'on' ? 'Meals will be provided.' : 'Meals will not be provided.'
					);
					break;
				default:
					break;
			}
		});
	});

	const goToOffersPage = () => {
		history.push(coreRoutes.offers);
	};

	return (
		<StyledLiveInsSummaryPage>
			<StyledHeadingContainer>
				<PageHeading> Your Live-Ins Summary </PageHeading>
			</StyledHeadingContainer>

			{formattedTexts.map((text, index) => (
				<Text key={index}>{text}</Text>
			))}

			<PricingCalculator
				dailyRate={
					Number(
						JSON.parse(
							Object.values(liveInsState['compensations']).find(
								(item) => item.live_in_key_look_up === liveInsKeys.daily_rate
							)?.trait_value_list
						)
					) * 100
				}
			/>
			<PrimaryButton onClick={goToOffersPage}>View Offers</PrimaryButton>
		</StyledLiveInsSummaryPage>
	);
};

const LiveInsPage = () => {
	return (
		<LiveInsProvider>
			<LivesInsContent />
		</LiveInsProvider>
	);
};

export default LiveInsPage;
