import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import PageHeading from '../../../../core/components/PageHeading';
import ProfessionalStatementForm from '../../../components/ProfessionalStatementForm';

import { useUpdateCareProInfoMutation } from '../../../api/mutations/useUpdateCareProInfoMutation';
import { useSetProfileOnboardMutation } from '../../../api/mutations/useSetProfileOnboardMutation';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useProfileOnboarding } from '../../../hooks/useProfileOnboarding';

import { authRoutes } from '../../../routes/constants';

import { StyledProfessionalStatementPage } from './styles';

const ProfessionalStatementPage = () => {
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { refreshAuthCarePro } = useAuth();

	const updateCareProInfo = useUpdateCareProInfoMutation();
	const setProfileOnboard = useSetProfileOnboardMutation();

	const { resetProfileOnboardingState } = useProfileOnboarding();

	const professionalStatementHandler = async (values) => {
		try {
			await updateCareProInfo.mutateAsync({
				professional_statement: values.professionalStatement,
			});

			await setProfileOnboard.mutateAsync();

			refreshAuthCarePro();
			resetProfileOnboardingState();
			history.push(authRoutes.onboarding.home);
		} catch (error) {
			console.error('Professional statement update failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledProfessionalStatementPage>
			<PageHeading>Professional Statement</PageHeading>

			<ProfessionalStatementForm
				initialValues={{
					professionalStatement: '',
				}}
				professionalStatementHandler={professionalStatementHandler}
				serverError={serverError}
			/>
		</StyledProfessionalStatementPage>
	);
};

export default ProfessionalStatementPage;
