import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Table from '../../../../../corev2/Table';
import PageHeading from '../../../core/components/PageHeading';

import { useFetchPaymentsQuery } from '../../api/queries/useFetchPaymentsQuery';

import { StyledPaymentsPage, StyledHeadingContainer, StyledTableContainer } from './styles';

const PaymentsPage = () => {
	const [payments, setPayments] = useState([]);

	const { data } = useFetchPaymentsQuery();

	useEffect(() => {
		if (data) {
			const paymentRows = data.map((payment) => [
				moment(new Date(payment.timestamp)).format('MM/DD/YYYY'),
				payment.hourly_rate.toString(),
				(payment.total_time_worked / 60)?.toString() || '',
				payment.subtotal?.toString() || '',
				(payment.fyi_share / 100)?.toString() || '',
				(payment.provider_payout / 100)?.toString() || '',
				payment.user.name?.toString() || '',
				payment.provider.display_name?.toString() || '',
			]);

			setPayments(paymentRows);
		}
	}, [data]);

	const headers = [
		'Date',
		'Hourly Rate',
		'Hours',
		'Subtotal',
		'FYI Share',
		'Provider Payout',
		'User',
		'Provider',
	];

	return (
		<StyledPaymentsPage>
			<StyledHeadingContainer>
				<PageHeading>Payments</PageHeading>
			</StyledHeadingContainer>

			<StyledTableContainer>
				<Table headers={headers} rows={payments} />
			</StyledTableContainer>
		</StyledPaymentsPage>
	);
};

export default PaymentsPage;
