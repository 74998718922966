import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import BackButton from '../../../../../corev2/BackButton';
import { ClientTile, OfferTile } from '../../../../../corev2/Tiles';
import Loader from '../../../../../corev2/Loader';
import Text from '../../../../../corev2/Text';

import ShiftSelector from '../../../core/components/ShiftSelector';
import OfferModal from '../../../core/components/OfferModal';
import PageHeading from '../../../core/components/PageHeading';

import OfferAction from '../../components/OfferAction';
import OffersFilter from '../../components/OffersFilter';

import { coreRoutes } from '../../../core/routes/constants';
import { shiftsRoutes } from '../../../shifts/routes/constants';
import { offersRoutes } from '../../routes/constants';
import { jobPostsRoutes } from '../../../jobPosts/routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useOffersFilter } from '../../hooks/useOffersFilter';

import { useFetchSingleJobPostQuery } from '../../api/queries/useFetchSingleJobPostQuery';
import { useFetchRegistryCarePlansForCareProsQuery } from '../../api/queries/useFetchRegistryCarePlansForCareProsQuery';
import { useFetchCareProOffersQuery } from '../../api/queries/useFetchCareProOffersQuery';
import { useOfferClientMutation } from '../../api/mutations/useOfferClientMutation';
import { useRespondToOfferMutation } from '../../api/mutations/useRespondToOfferMutation';
import { useContactClientMutation } from '../../api/mutations/useContactClientMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { getLiveInDataFromCarePlan } from '../../../core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import {
	StyledOffersPage,
	StyledJobPostBanner,
	StyledCloseBannerIcon,
	StyledPageHeadingContainer,
	StyledActionSection,
	StyledActionsContainer,
	StyledCareProsContainer,
	StyledCategoryDescription,
} from './styles';

const OffersPage = (props) => {
	const [individualJobPostMode, setIndividualJobPostMode] = useState(false);
	const [isShiftSelectionModalOpen, setIsShiftSelectionModalOpen] = useState(false);
	const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
	const [selectedCarePlan, setSelectedCarePlan] = useState(null);
	const [selectedOffer, setSelectedOffer] = useState(null);
	const history = useHistory();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const status = searchParams.get('status') || 'care-plans';
	const jobPostId = searchParams.get('job_post_id');

	const { authCarePro } = useAuth();

	const { data: carePlans, isLoading: isLoadingCarePlans } =
		useFetchRegistryCarePlansForCareProsQuery();

	const {
		data: offers,
		isLoading: isLoadingOffers,
		refetch: refetchOffers,
	} = useFetchCareProOffersQuery(individualJobPostMode ? jobPostId : null);

	const {
		data: jobPost,
		isLoading: isLoadingJobPost,
		refetch: updateJobPost,
	} = useFetchSingleJobPostQuery(jobPostId);

	useEffect(() => {
		if (jobPostId) {
			setIndividualJobPostMode(true);
		}

		return () => setIndividualJobPostMode(false);
	}, [jobPostId]);

	const filteredCarePlans = useMemo(() => {
		return carePlans && jobPostId
			? carePlans.filter((carePlan) => carePlan.id === jobPost.care_plan_id)
			: carePlans;
	}, [carePlans, jobPostId]);

	const { stats, filteredOffers } = useOffersFilter(filteredCarePlans, offers);

	const offerClient = useOfferClientMutation();
	const contactClient = useContactClientMutation();
	const respondToOffer = useRespondToOfferMutation();

	const changeSearchParam = (name, status) => {
		searchParams.set('status', status);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const chatWithClientHandler = async (userId) => {
		try {
			const data = await useContactClientMutation.mutateAsync({
				authCareProcareProId,
				userId: authUser.id,
			});

			history.push(coreRoutes.chat);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const offerClientHandler = async (hourlyRate, incomingShiftIds) => {
		try {
			await offerClient.mutateAsync({
				jobPostId: jobPost && jobPost.id,
				userId: selectedCarePlan.coordinator.id,
				hourlyRate,
				shiftIds: incomingShiftIds,
			});

			refetchOffers();
			setIsShiftSelectionModalOpen(false);
			Notification(notifications.jobPosts.careProOffered);
		} catch (error) {
			console.error('Offer care pro failed', error);
		}
	};

	const respondToOfferHandler = async (offerId, accept) => {
		try {
			await respondToOffer.mutateAsync({
				offerId,
				accept,
			});

			refetchOffers();
			setIsOfferModalOpen(false);
			Notification(notifications.jobPosts.careProOffered);
		} catch (error) {
			console.error('Responding to offer failed', error);
		}
	};

	const shiftSelectionModalHandler = (carePlan) => {
		setIsShiftSelectionModalOpen(!isShiftSelectionModalOpen);
		setSelectedCarePlan(isShiftSelectionModalOpen ? null : carePlan);
	};

	const viewOfferModalHandler = (offer) => {
		setIsOfferModalOpen(!isOfferModalOpen);
		setSelectedOffer(isOfferModalOpen ? null : offer);
	};

	const goToViewOfferPage = async (careProId) => {
		const job = jobPost.jobs.find((job) => job.provider.id === careProId);

		history.push(jobPostsRoutes.viewOfferPage(jobPost.id, job.id));
	};

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	const goToOffersPage = () => {
		history.push({
			pathname: offersRoutes.home,
			search: '',
		});
	};

	const viewAllOffers = () => {
		history.push({
			pathname: offersRoutes.home,
			search: 'status=care-plans',
		});
		setIndividualJobPostMode(false);
	};

	const goToShiftsPage = () => {
		history.push(coreRoutes.shifts);
	};

	const getCategoryDescriptions = () => {
		let description;

		switch (status) {
			case 'search-care-pros':
				description = (
					<Text size='large'>
						Browse all available Care Pros in your registry. Use filters to find
						qualified candidates and send invitations directly.
					</Text>
				);
				break;

			case 'invited':
				description = (
					<Text size='large'>
						Care Pros you’ve invited to apply for the job. Monitor responses and follow
						up if needed to ensure timely hiring.
					</Text>
				);
				break;

			case 'applied':
				description = (
					<Text size='large'>
						Care Pros who have applied for your job. Review their applications,
						qualifications, and experience before proceeding with interviews or offers.
					</Text>
				);
				break;

			case 'offered':
				description = (
					<Text size='large'>
						Candidates you’ve extended job offers to. Wait for their responses or
						contact them to expedite the hiring process.
					</Text>
				);
				break;

			case 'active':
				description = (
					<Text size='large'>
						Care Pros currently working on your job. Manage assignments, monitor
						progress, and communicate for seamless job execution.
					</Text>
				);
				break;

			case 'inactive':
				description = (
					<Text size='large'>
						Care Pros who declined offers, completed assignments, or whose contracts
						ended. Review past work history for future consideration or new job
						postings.
					</Text>
				);
				break;

			default:
				description = (
					<Text size='large'>
						Manage Care Pros related to your job posts. Select a category to view Care
						Pros or create a new job post using the button below.
					</Text>
				);
		}

		return description;
	};

	if (isLoadingJobPost || isLoadingCarePlans || isLoadingOffers) {
		return <Loader />;
	}

	return (
		<StyledOffersPage>
			{individualJobPostMode && (
				<>
					<StyledJobPostBanner>
						<Text inverted>
							You're viewing all offers related to the job post "{jobPost?.title}".
							Close this banner to go to the offers page to view all offers for your
							entire care plan.
						</Text>

						<StyledCloseBannerIcon onClick={viewAllOffers}>x</StyledCloseBannerIcon>
					</StyledJobPostBanner>
					<StyledPageHeadingContainer>
						<BackButton withText clickHandler={goToOffersPage} />
						<PageHeading>{jobPost.title}</PageHeading>
					</StyledPageHeadingContainer>
				</>
			)}

			{isShiftSelectionModalOpen && (
				<ShiftSelector
					title={`Send offer to ${selectedCarePlan?.coordinator?.name}`}
					shifts={selectedCarePlan.shifts}
					shiftSelectionModalHandler={shiftSelectionModalHandler}
					offerClientHandler={offerClientHandler}
				/>
			)}

			{isOfferModalOpen && (
				<OfferModal
					title={`Shifts Offered to ${selectedOffer.provider.display_name} at ${selectedOffer.hourly_rate}/hr`}
					offer={selectedOffer}
					viewOfferModalHandler={viewOfferModalHandler}
					respondToOfferHandler={respondToOfferHandler}
					isModificationAllowed={
						selectedOffer.status === 'offered' &&
						selectedOffer.offer_made_by === 'client'
					}
					isValid={selectedOffer.offered_shifts.every(
						(offeredShift) => offeredShift.shift.status === 'pending'
					)}
				/>
			)}

			<StyledActionSection>
				<StyledActionsContainer>
					<OffersFilter stats={stats} changeSearchParam={changeSearchParam} />

					<OfferAction
						title='Shifts'
						description='Manage the shifts assigned to you.'
						goToPage={goToShiftsPage}
					/>
				</StyledActionsContainer>

				<StyledCareProsContainer>
					<StyledCategoryDescription>
						{getCategoryDescriptions()}
					</StyledCategoryDescription>

					{stats.carePlans.isActive &&
						filteredCarePlans.map((carePlan) => {
							const {
								mealsProvided,
								bedroomAvailable,
								bathroomAvailable,
								travelMilesCompensated,
							} = getLiveInDataFromCarePlan(carePlan);
							return (
								<ClientTile
									key={carePlan.id}
									id={carePlan.id}
									careType={carePlan.care_type}
									mealsProvided={mealsProvided}
									bedAvailable={bedroomAvailable}
									bathroomAvailable={bathroomAvailable}
									travelMilesCompensated={travelMilesCompensated}
									name={`${
										carePlan?.cr_first_name && carePlan?.cr_last_name
											? `${carePlan?.cr_first_name} ${carePlan?.cr_last_name}`
											: `${carePlan.cc_first_name} ${carePlan.cc_last_name}`
									}`}
									customButtonText={'Offer'}
									onCustomButtonClick={() => shiftSelectionModalHandler(carePlan)}
									viewCareProHandler={viewCareProHandler}
								/>
							);
						})}

					{!stats.carePlans.isActive &&
						filteredOffers.map((offer) => {
							return (
								<OfferTile
									key={offer.id}
									client={offer.offer_made_by === 'client' ? offer.user : null}
									carePro={
										offer.offer_made_by === 'carePro' ? offer.provider : null
									}
									hourly_rate={offer.hourly_rate}
									offer_text='Ill do it'
									customButtonText={'View Offer'}
									onCustomButtonClick={() => viewOfferModalHandler(offer)}
									isValid={offer.offered_shifts.every(
										(offeredShift) => offeredShift.shift.status === 'pending'
									)}
								/>
							);
						})}

					{stats.carePlans.isActive && carePlans.length === 0 && (
						<Text>No care plans found.</Text>
					)}

					{!stats.carePlans.isActive && carePlans.length === 0 && (
						<Text>No offers found.</Text>
					)}
				</StyledCareProsContainer>
			</StyledActionSection>
		</StyledOffersPage>
	);
};

export default OffersPage;
