import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { StyledLoginIndicator, StyledUsername, StyledIconContainer } from './styles';

const LoginIndicator = (props) => {
	const { admin, onClick } = props;

	return (
		<StyledLoginIndicator onClick={onClick} isActive={true}>
			<StyledUsername isActive={true}>{admin.name}</StyledUsername>

			<StyledIconContainer isActive={true}>
				<FontAwesomeIcon icon={faUser} size='sm' />
			</StyledIconContainer>
		</StyledLoginIndicator>
	);
};

export default LoginIndicator;
