import { useMutation } from '@tanstack/react-query';

import { CARE_PRO_MODEL } from '../../../core/api/models';

import api from '../../../core/utility/graphQL';

const updateCareProInfo = async (inputs) => {
	try {
		const data = await api.graph({
			query: `mutation {
					updateProviderInfo(
						profile_info: ${api.graphStringify(inputs)},
					) {
                        ${CARE_PRO_MODEL}
					}
				}`,
		});

		return data.updateProviderInfo;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateCareProInfoMutation = () => {
	return useMutation({
		mutationKey: ['updateCareProInfo'],
		mutationFn: (values) => {
			return updateCareProInfo(values);
		},
	});
};
