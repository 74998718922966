import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { FieldGroup, ErrorMessage } from '../../../../../corev2/Forms';
import { TextInput, RadioInput, Select, Checkbox } from '../../components/LiveInsFormElements';

import { liveInsRoutes } from '../../routes/constants';

import { useUpdateCarePlanFormAnswersMutation } from '../../api/mutations/useUpdateCarePlanFormAnswersMutation';
import { useHandleCompletedLiveInsWizard } from '../../api/mutations/useHandleCompletedLiveInsWizardMutation';

import { LiveInsProvider } from '../../context/LiveInsContext';
import { useLiveInsContext } from '../../hooks/useLiveInsContext';

import { useAuth } from '../../../core/hooks/useAuth';

import {
	StyledLiveInsPage,
	StyledLiveInsForm,
	StyledLiveInsFormHeading,
	StyledLiveInsHeadingContainer,
	StyledLiveInsHeading,
	StyledLiveInsHeadingSub,
	StyledLiveInsWrwaper,
	StyledLiveInfFormContainer,
	StyledLiveInFormDescription,
} from './styles';

const usStates = [
	{ name: 'Florida', minimumWage: 8.65 },
	{ name: 'California', minimumWage: 14.0 },
];

const breakHours = 8;

const LiveInsContent = () => {
	const [forms, setForms] = useState([]);
	const [form, setForm] = useState(null);
	const [serverError, setServerError] = useState(null);
	const [belowMinimumWageAcknowledgment, setBelowMinimumWageAcknowledgment] = useState(false);
	const [belowMinimumRestTimeAcknowledgment, setBelowMinimumRestTimeAcknowledgment] =
		useState(false);
	const [validationSchema, setValidationSchema] = useState(z.object({}));
	const { formSlug } = useParams();
	const history = useHistory();

	const { authUser } = useAuth();

	const { liveInsState, mutateLiveInsState } = useLiveInsContext();

	const updateCarePlanFormAnswers = useUpdateCarePlanFormAnswersMutation();
	const handleCompletedLiveInsWizard = useHandleCompletedLiveInsWizard();

	// Fetch the form data when the user is authenticated
	useEffect(() => {
		if (authUser) {
			const allForms = authUser.carePlan.liveInCare.trait_template.sections;
			setForms(allForms);

			// Find the specific form based on the slug
			const activeForm = allForms.find((f) => f.slug === formSlug);
			setForm(activeForm);

			if (activeForm) {
				// Create dynamic Zod schema
				const zodSchema = z.object(
					activeForm.traits.reduce((schema, trait) => {
						if (trait.trait_type === 'TEXT') {
							schema[trait.live_in_key_look_up] = z
								.string()
								.min(1, `${trait.label} is required`) // Ensure at least 1 character
								.max(255, `${trait.label} cannot exceed 255 characters`); // Optional: Limit length
						} else if (trait.trait_type === 'NUMBER') {
							schema[trait.live_in_key_look_up] = z
								.number({
									invalid_type_error: `${trait.label} must be a valid number`,
								})
								.min(0, `${trait.label} must be at least 0`); // Ensure it's a valid number
						} else if (trait.trait_type === 'SELECT_MULTI_INPUT') {
							schema[trait.live_in_key_look_up] = z
								.array(z.string())
								.min(1, `${trait.label} is required`);
						}
						return schema;
					}, {})
				);
				setValidationSchema(zodSchema);
			} else {
				history.push(liveInsRoutes.form(allForms[0].slug));
			}
		}
	}, [authUser, formSlug]);

	// Generate initial values dynamically
	const initialValues = form
		? form.traits.reduce((acc, trait) => {
				acc[trait.live_in_key_look_up] =
					trait.trait_type === 'SELECT_MULTI_INPUT' ? [] : '';
				return acc;
		  }, {})
		: {};

	// Initialize Formik
	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true, // Ensures formik updates when form data changes
		validationSchema: toFormikValidationSchema(validationSchema),
		onSubmit: async (values) => {
			/**
			 * Ugly hard check for breaks
			 */
			if (formSlug === 'breaks') {
				if (
					formik.values.number_of_break_hours + formik.values.number_of_sleep_hours <
						breakHours &&
					!formik.values.below_minimum_rest_time_acknowledgment
				) {
					setBelowMinimumRestTimeAcknowledgment(true);
					setServerError(
						'Please acknowledge that you are providing less than 8 hours of rest time.'
					);
					return;
				}
			}

			/**
			 * Ugly hard check for comepensation
			 */
			if (formSlug === 'compensations') {
				const selectedState = usStates.find(
					(state) => state.name === formik.values.live_in_state[0]
				);
				if (
					selectedState &&
					formik.values.daily_rate < selectedState.minimumWage &&
					!formik.values.below_minimum_wage_acknowledgment
				) {
					setBelowMinimumWageAcknowledgment(true);
					setServerError(
						'Please acknowledge that you are paying less than minimum wage.'
					);
					return;
				}
			}

			// Log all form values in a structured format
			const formattedValues = Object.keys(values).map((key) => {
				const trait = form.traits.find((t) => t.live_in_key_look_up === key);

				let trait_value_list;

				if (trait.trait_type !== 'SELECT_MULTI_INPUT') {
					trait_value_list = [String(values[key])];
				} else {
					trait_value_list = values[key].map(String);
				}

				return {
					trait_id: trait.id,
					version: JSON.parse(trait.meta_data).version,
					trait_type: trait.trait_type,
					trait_type: trait?.trait_type,
					trait_value_list,
					live_in_key_look_up: trait?.live_in_key_look_up,
				};
			});

			await updateCarePlanFormAnswers.mutateAsync({
				formId: authUser.carePlan.liveInCare.id,
				answers: formattedValues,
			});

			mutateLiveInsState(formSlug, formattedValues);

			const currentFormIndex = forms.findIndex((f) => f.slug === formSlug);
			const nextForm = forms[currentFormIndex + 1];

			if (nextForm && Object.keys(nextForm).length > 0) {
				history.push(liveInsRoutes.form(nextForm.slug));
				setBelowMinimumRestTimeAcknowledgment(false);
				setBelowMinimumWageAcknowledgment(false);
			} else {
				await handleCompletedLiveInsWizard.mutateAsync({
					carePlanId: authUser.carePlan.id,
				});
				history.push(liveInsRoutes.summary);
			}
		},
	});

	/**
	 * Ugly handler for acknowledgement checkboxes
	 */
	const handleAcknowledgement = (e) => {
		formik.setFieldValue(e.target.name, e.target.checked);
		setServerError(null);
	};

	// Handle case when form is not found
	if (!form) {
		return (
			<StyledLiveInsPage>
				<StyledLiveInsHeadingContainer>
					<StyledLiveInsHeading>Error 404</StyledLiveInsHeading>
					<StyledLiveInsHeadingSub>Form not found</StyledLiveInsHeadingSub>
				</StyledLiveInsHeadingContainer>
			</StyledLiveInsPage>
		);
	}

	return (
		<StyledLiveInsPage>
			<StyledLiveInsHeadingContainer>
				<StyledLiveInsHeading>Welcome to your Live-In guide</StyledLiveInsHeading>
				<StyledLiveInsHeadingSub>
					Please answer the following questions
				</StyledLiveInsHeadingSub>
			</StyledLiveInsHeadingContainer>

			<StyledLiveInsWrwaper>
				<StyledLiveInsForm onSubmit={formik.handleSubmit}>
					<StyledLiveInsFormHeading>{form.label}</StyledLiveInsFormHeading>

					<StyledLiveInfFormContainer>
						{form.traits.map((trait, index) => {
							const fieldName = trait.live_in_key_look_up;

							if (trait.trait_type === 'TEXT' || trait.trait_type === 'NUMBER') {
								return (
									<FieldGroup key={index}>
										<TextInput
											name={fieldName}
											label={trait.label}
											type={trait.trait_type.toLowerCase()}
											value={formik.values[fieldName]}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											isRequired
										/>
										{formik.touched[fieldName] && formik.errors[fieldName] && (
											<ErrorMessage>{formik.errors[fieldName]}</ErrorMessage>
										)}
									</FieldGroup>
								);
							}

							if (trait.trait_type === 'RADIO_INPUT') {
								return (
									<FieldGroup key={index}>
										<RadioInput
											name={fieldName}
											label={trait.label}
											options={JSON.parse(trait.meta_data).options}
											value={formik.values[fieldName]}
											onChange={(e) =>
												formik.setFieldValue(fieldName, e.target.value)
											}
											onBlur={formik.handleBlur}
											isRequired
										/>
										{formik.touched[fieldName] && formik.errors[fieldName] && (
											<ErrorMessage>{formik.errors[fieldName]}</ErrorMessage>
										)}
									</FieldGroup>
								);
							}

							if (trait.trait_type === 'SELECT_MULTI_INPUT') {
								return (
									<FieldGroup key={index}>
										<Select
											type='select'
											name={fieldName}
											options={JSON.parse(trait.meta_data).options}
											label={trait.label}
											value={formik.values[fieldName]}
											onChange={(e) => {
												const selectedOptions = Array.from(
													e.target.selectedOptions,
													(option) => option.value
												);
												formik.setFieldValue(fieldName, selectedOptions);
											}}
											onBlur={formik.handleBlur}
											isRequired
											multiple
										/>
										{formik.touched[fieldName] && formik.errors[fieldName] && (
											<ErrorMessage>{formik.errors[fieldName]}</ErrorMessage>
										)}
									</FieldGroup>
								);
							}

							return null;
						})}
					</StyledLiveInfFormContainer>

					{belowMinimumRestTimeAcknowledgment && (
						<Checkbox
							name='below_minimum_rest_time_acknowledgment'
							label="I acknowledge that I'm providing less than 8 hours of rest time."
							onChange={handleAcknowledgement}
						/>
					)}

					{belowMinimumWageAcknowledgment && (
						<Checkbox
							name='below_minimum_wage_acknowledgment'
							label="I acknowledge that I'm paying less than minimum wage."
							onChange={handleAcknowledgement}
						/>
					)}

					{serverError && <ErrorMessage>{serverError}</ErrorMessage>}
					<PrimaryButton type='submit'>Submit</PrimaryButton>
				</StyledLiveInsForm>

				<StyledLiveInFormDescription>{form.description}</StyledLiveInFormDescription>
			</StyledLiveInsWrwaper>
		</StyledLiveInsPage>
	);
};

const LiveInsPage = () => {
	return (
		<LiveInsProvider>
			<LiveInsContent />
		</LiveInsProvider>
	);
};

export default LiveInsPage;
