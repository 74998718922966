import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CLIENT_MODEl } from '../../../core/api/models';

const registerUser = async ({
	email,
	password,
	phone,
	firstName,
	lastName,
	displayName,
	authCode,
	registryId,
}) => {
	try {
		const data = await api.graph({
			query: `mutation{
					registerUser(
						email: "${email}",
						password: "${password}"
						phoneNumber: "${phone}",
						name: "${firstName} ${lastName}",
                        display_name: "${displayName}",
						auth_code: "${authCode}",
						registry_id: ${registryId}
					) {
						token
						user {
							${CLIENT_MODEl}
						}
					
					}
				}`,
		});

		return data.registerUser;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRegisterUserMutation = () => {
	return useMutation({
		mutationKey: ['registerUser'],
		mutationFn: (values) => {
			return registerUser(values);
		},
	});
};
