import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../../hooks/useAuth';

import { StyledLoginIndicator, StyledUsername, StyledIconContainer } from './styles';

const LoginIndicator = (props) => {
	const { onClick } = props;

	const { isAuthenticated, authUser } = useAuth();

	return (
		<StyledLoginIndicator onClick={onClick} isActive={isAuthenticated}>
			<StyledUsername isActive={isAuthenticated}>
				{isAuthenticated ? authUser.name : 'Login'}
			</StyledUsername>

			<StyledIconContainer isActive={isAuthenticated}>
				<FontAwesomeIcon icon={faUser} size='sm' />
			</StyledIconContainer>
		</StyledLoginIndicator>
	);
};

export default LoginIndicator;
