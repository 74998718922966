import moment from 'moment';

const shiftStatuses = {
	PENDING: 'pending',
	ASSIGNED: 'assigned',
	CLOCKED_IN: 'clocked_in',
	CLOCKED_OUT: 'clocked_out',
	SUBMITTED: 'submitted',
	CANCELED: 'canceled',
	DISPUTED: 'disputed',
	PAID: 'paid',
};

const getEventTitle = (status) => {
	switch (status) {
		case shiftStatuses.PENDING:
			return 'Pending';
		case shiftStatuses.ASSIGNED:
			return 'Assigned';
		case shiftStatuses.CLOCKED_IN:
			return 'Clocked In';
		case shiftStatuses.CLOCKED_OUT:
			return 'Clocked Out';
		case shiftStatuses.SUBMITTED:
			return 'Submitted';
		case shiftStatuses.CANCELED:
			return 'Canceled';
		case shiftStatuses.DISPUTED:
			return 'Disputed';
		case shiftStatuses.PAID:
			return 'Paid';
		default:
			return 'Available';
	}
};

export const generateEvents = (shifts) => {
	const events = [];

	// Filter out deleted shifts
	const validShifts = shifts.filter((shift) => shift.status !== 'deleted');

	validShifts.forEach((shift) => {
		// Convert the existing JS date string into a proper Date object and then to UTC
		const start = moment(new Date(shift.start_time)).utc().toDate();
		const end = moment(new Date(shift.end_time)).utc().toDate();

		events.push({
			title: getEventTitle(shift.status),
			start,
			end,
			allDay: false,
			resource: { shift },
		});
	});

	// Sort events by start time
	events.sort((a, b) => a.start - b.start);

	return events;
};
