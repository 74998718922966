import liveInsKeys from '../constants/liveInsKeys';

export const getLiveInDataFromCarePlan = (carePlan) => {
	const { liveInCare } = carePlan;

	let mealsProvided = false;
	let bedroomAvailable = false;
	let bathroomAvailable = false;
	let travelMilesCompensated = false;

	if (liveInCare) {
		const { form_answers, trait_template } = liveInCare;
		const { sections } = trait_template;

		sections.map((section) => {
			const { traits } = section;

			traits.map((trait) => {
				const { live_in_key_look_up, id } = trait;

				const formAnswer = form_answers.find((answer) => answer.trait_id === id);

				if (formAnswer) {
					if (live_in_key_look_up === liveInsKeys.meals_provided) {
						mealsProvided = JSON.parse(formAnswer.trait_value);
						mealsProvided = mealsProvided === 'on' ? true : false;
					}

					if (live_in_key_look_up === liveInsKeys.private_bedroom_availalable) {
						bedroomAvailable = JSON.parse(formAnswer.trait_value);
						bedroomAvailable = bedroomAvailable === 'on' ? true : false;
					}

					if (live_in_key_look_up === liveInsKeys.private_bathroom_available) {
						bathroomAvailable = JSON.parse(formAnswer.trait_value);
						bathroomAvailable = bathroomAvailable === 'on' ? true : false;
					}

					if (live_in_key_look_up === liveInsKeys.travel_miles_compensated) {
						console.log(formAnswer);
						travelMilesCompensated = JSON.parse(formAnswer.trait_value);
						travelMilesCompensated = travelMilesCompensated === 'on' ? true : false;
					}
				}
			});
		});
	}

	return { mealsProvided, bedroomAvailable, bathroomAvailable, travelMilesCompensated };
};
