import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import { Input } from '../../../../corev2/Forms';
import { PrimaryButton } from '../../../../corev2/Buttons';

import {
	StyledUploadPhotoForm,
	StyledDocumentTitle,
	StyledDocumentUploadArea,
	StyledUploadFilesText,
	StyledButtonContainer,
	StyledClearFileIcon,
} from './styles';

const UploadPhotoForm = (props) => {
	const { onUpload, onClearImage, isLoading } = props;

	const [selectedFile, setSelectedFile] = useState(null);

	const handleDrop = (acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			setSelectedFile(acceptedFiles[0]);
		}
	};

	const handleClearFile = () => {
		onClearImage();
		setSelectedFile(null);
	};

	const handleUpload = async () => {
		await onUpload(selectedFile);
		setSelectedFile(null);
	};

	return (
		<StyledUploadPhotoForm>
			<StyledDocumentTitle>{selectedFile && selectedFile.name}</StyledDocumentTitle>

			{selectedFile && <StyledClearFileIcon onClick={handleClearFile}>x</StyledClearFileIcon>}

			<Dropzone onDrop={handleDrop}>
				{({ getRootProps, getInputProps }) => (
					<StyledDocumentUploadArea {...getRootProps()}>
						<Input {...getInputProps()} />
						<StyledUploadFilesText>
							{selectedFile ? selectedFile.name : 'Drag and drop file(s)'}
						</StyledUploadFilesText>
					</StyledDocumentUploadArea>
				)}
			</Dropzone>

			{selectedFile && (
				<StyledButtonContainer>
					<PrimaryButton
						size='small'
						type='submit'
						disabled={isLoading || !selectedFile}
						onClick={handleUpload}
					>
						{isLoading ? 'Uploading...' : 'Upload'}
					</PrimaryButton>
				</StyledButtonContainer>
			)}
		</StyledUploadPhotoForm>
	);
};

export default UploadPhotoForm;
