import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import DescriptivePage from '../../../../../corev2/DescriptivePage';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import Text from '../../../../../corev2/Text';

import JobPost from '../../components/JobPost';

import { jobPostsRoutes } from '../../routes/constants';
import { offersRoutes } from '../../../offers/routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchRegistryJobPostsQuery } from '../../api/queries/useFetchRegistryJobPostsQuery';

import { StyledJobPostsContainer, StyledSideContent, StyledJobPostsText } from './styles';

const JobPostsPage = () => {
	const [jobPosts, setJobPosts] = useState([]);

	const history = useHistory();

	const { authCarePro } = useAuth();

	const { data } = useFetchRegistryJobPostsQuery(authCarePro.careProRegistryStatus.registry_id);

	useEffect(() => {
		if (data) {
			setJobPosts(data);
		}
	}, [data]);

	const goToJobPostOfferPage = (jobPostId) => {
		history.push(`${offersRoutes.home}?job_post_id=${jobPostId}`);
	};

	return (
		<DescriptivePage
			heading='Job Posts'
			descriptionContent={
				<StyledSideContent>
					<Text size='large'>
						Click to view a specific job post and the offers received and made for that
						job post.
					</Text>
				</StyledSideContent>
			}
		>
			<StyledJobPostsContainer>
				{jobPosts.map((jobPost) => {
					return (
						<JobPost
							key={jobPost.id}
							title={jobPost.title}
							description={jobPost.description}
							startDate={jobPost.start_date}
							endDate={jobPost.end_date}
							offers={10}
							careType={jobPost.carePlan.care_type}
							goToJobPostOfferPage={() => goToJobPostOfferPage(jobPost.id)}
						/>
					);
				})}

				{jobPosts.length === 0 && (
					<StyledJobPostsText> No job posts in this category. </StyledJobPostsText>
				)}
			</StyledJobPostsContainer>
		</DescriptivePage>
	);
};

export default JobPostsPage;
