import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PrimaryButton, DangerButton } from '../../../../../corev2/Buttons';
import Loader from '../../../../../corev2/Loader';
import UploadPhotoForm from '../../components/UploadPhotoForm';
import EditDetailsForm from '../../components/EditDetailsForm';
import PayoutMethodForm from '../../components/PayoutMethodForm';
import PayoutMethodCard from '../../components/PayoutMethodCard';
import PayoutMethodBank from '../../components/PayoutMethodBank';

import {
	StyledContainer,
	StyledWelcomeSection,
	StyledWelcomeHeading,
	StyledWelcomeText,
	StyledContentWrapper,
	StyledAccountSection,
	StyledInnerAccountSection,
	StyledPayoutSection,
	StyledInnerPayoutSection,
	StyledSectionTitle,
	StyledImageContainer,
	StyledEditIconContainer,
	StyledImage,
	StyledButtonsContainer,
	StyledPayoutMethodsContainer,
	StyledPayoutInfoText,
} from './styles.js';

import { useFetchPayoutMethodsQuery } from '../../api/queries/useFetchPayoutMethods';
import { useUpdateCareProMutation } from '../../api/mutations/useUpdateCareProMutation';
import { useDeletePayoutMethodMutation } from '../../api/mutations/useDeletePayoutMethodMutation';
import { useAddExternalCardSourceMutation } from '../../api/mutations/useAddExternalCardSourceMutation';
import { useChangeDefaultPayoutSourceMutation } from '../../api/mutations/useChangeDefaultPayoutSourceMutation';
import { useCreateCareProFinancialConnectionSessionQuery } from '../../api/queries/useCreateCareProFinancialConnectionSessionQuery';

import Notification from '../../../../../carePro/modules/core/lib/notification';
import { notifications } from '../../../../../carePro/modules/core/constants';

import uploadFilesToAWSS3 from '../../../core/utility/uploadFilesToAWSS3';

import { useAuth } from '../../../core/hooks/useAuth';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const DashboardPageContent = () => {
	const [isUploadingImage, setIsUploadingImage] = useState(false);
	const [photoEditMode, setPhotoEditMode] = useState(false);
	const [serverError, setServerError] = useState('');

	const { authCarePro, refreshAuthCarePro, logoutCarePro } = useAuth();

	const stripe = useStripe();

	const {
		data: payoutMethods,
		isLoading: isLoadingPayoutMethods,
		refetch: refreshPayoutsData,
	} = useFetchPayoutMethodsQuery();

	const updateCarePro = useUpdateCareProMutation();
	const deletePayoutMethod = useDeletePayoutMethodMutation();
	const addExternalCardSource = useAddExternalCardSourceMutation();
	const changeDefaultPayoutSource = useChangeDefaultPayoutSourceMutation();
	const createFinancialConnectionSession = useCreateCareProFinancialConnectionSessionQuery();

	const createFinancialConnectionHandler = async () => {
		try {
			const financialConnectionToken = await createFinancialConnectionSession.mutateAsync();

			const stripeResponse = await stripe.collectBankAccountToken({
				clientSecret: financialConnectionToken,
			});

			if (stripeResponse.error) {
				console.error(stripeResponse.error);
				return;
			}

			if (stripeResponse.token) {
				await addExternalCardSource.mutateAsync({
					externalCardTokenId: stripeResponse.token.id,
				});

				refreshPayoutsData();
			}

			refreshPayoutsData();
		} catch (error) {
			console.log('Error creating financial connection', error);
		}
	};

	const updateCareProHandler = async (values) => {
		try {
			await updateCarePro.mutateAsync({
				careProId: authCarePro.id,
				profileInfo: { email: values.email, phone_number: values.phone },
			});

			// Notification(notifications.dashboard.accountInfo.updated);
		} catch (error) {
			console.error('Updating care pro failed', error);
			set;
		}
	};

	const addPayoutMethodHandler = async (tokenId) => {
		try {
			await addExternalCardSource.mutateAsync({
				externalCardTokenId: tokenId,
			});

			// Notification(notifications.dashboard.payoutMethods.payoutMethodAdded);
			refreshPayoutsData();
		} catch (error) {
			console.error('Add Payout method handler failed.', error);
		}
	};

	const updateDefaultPayoutMethodHandler = async (sourceId) => {
		try {
			await changeDefaultPayoutSource.mutateAsync({
				externalCardTokenId: sourceId,
			});

			// Notification(notifications.dashboard.paymentMethods.defaultPaymentMethodChanged);
			refreshPayoutsData();
		} catch (error) {
			console.error('Updating default payment method failed', error);
		}
	};

	const deletePayoutMethodHandler = async (sourceId) => {
		try {
			await deletePayoutMethod.mutateAsync({
				externalCardTokenId: sourceId,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodDeleted);
			refreshPayoutsData();
		} catch (error) {
			console.error('Deleting payment method failed', error);
		}
	};

	const handleUploadDocuments = async (file) => {
		try {
			// Upload the file to AWS S3
			setIsUploadingImage(true);
			const uploadedFile = await uploadFilesToAWSS3({ [authCarePro.id]: { file } });
			console.log(uploadedFile);
			const updatedCarePro = await updateCarePro.mutateAsync({
				profileInfo: {
					image_url: uploadedFile[authCarePro.id].url,
				},
			});

			refreshAuthCarePro();
			setIsUploadingImage(false);
		} catch (error) {
			console.error('Photo upload failed:', error);
		}
	};

	const handleClearImage = async () => {
		try {
			setIsUploadingImage(true);
			const updatedCarePro = await updateCarePro.mutateAsync({
				image_url: '',
			});

			refreshAuthCarePro();
			setIsUploadingImage(false);
		} catch (error) {
			console.error('Photo clear failed:', error);
		}
	};

	const logoutHandler = () => {
		logoutCarePro();
		Notification(notifications.auth.logout);
	};

	if (isLoadingPayoutMethods) return <Loader />;

	return (
		<StyledContainer>
			<StyledWelcomeSection>
				<StyledWelcomeHeading>WELCOME BACK</StyledWelcomeHeading>
				<StyledWelcomeText>
					This is the Settings section for CarePros such as yourself
				</StyledWelcomeText>
			</StyledWelcomeSection>

			<StyledContentWrapper>
				<StyledAccountSection>
					<StyledSectionTitle>ACCOUNT</StyledSectionTitle>
					<StyledInnerAccountSection>
						<StyledImageContainer>
							<StyledEditIconContainer
								onClick={() => setPhotoEditMode(!photoEditMode)}
							>
								<FontAwesomeIcon icon={faPencilAlt} size='sm' />
							</StyledEditIconContainer>
							<StyledImage src={authCarePro.image_url}></StyledImage>
						</StyledImageContainer>

						{photoEditMode && (
							<UploadPhotoForm
								isLoading={isUploadingImage}
								onUpload={handleUploadDocuments}
								onClearImage={handleClearImage}
							/>
						)}

						<EditDetailsForm
							initialValues={{
								email: authCarePro.email,
								phone: authCarePro.phone_number,
							}}
							updateCareProHandler={updateCareProHandler}
							serverError={serverError}
						/>

						<StyledButtonsContainer>
							<PrimaryButton size='small'>Change Password</PrimaryButton>
							<PrimaryButton size='small'>Export Invoices</PrimaryButton>
							<DangerButton size='small' onClick={logoutHandler}>
								Log Out
							</DangerButton>
						</StyledButtonsContainer>
					</StyledInnerAccountSection>
				</StyledAccountSection>

				<StyledPayoutSection>
					<StyledSectionTitle>PAYOUT METHODS</StyledSectionTitle>
					<StyledInnerPayoutSection>
						<StyledPayoutInfoText>
							In order to provide our best service we must have a payout method on
							file. Please add your card information below.
						</StyledPayoutInfoText>

						<StyledPayoutMethodsContainer>
							{payoutMethods.payoutMethods.map((payoutMethod) => {
								const { bank, card } = payoutMethod;

								if (payoutMethod.type === 'card') {
									return (
										<PayoutMethodCard
											key={payoutMethod.id}
											id={payoutMethod.id}
											last4={card.last_4}
											expiryMonth={card.exp_month}
											expiryYear={card.exp_year}
											cardBrand={card.brand}
											isDefault={payoutMethod.default_method}
											updateDefaultPayoutMethodHandler={
												updateDefaultPayoutMethodHandler
											}
											deletePayoutMethodHandler={deletePayoutMethodHandler}
										/>
									);
								}

								if (payoutMethod.type === 'bank') {
									return (
										<PayoutMethodBank
											key={payoutMethod.id}
											id={payoutMethod.id}
											bankName={bank.bank_name}
											last4={bank.last_4}
											isDefault={payoutMethod.default_method}
											updateDefaultPayoutMethodHandler={
												updateDefaultPayoutMethodHandler
											}
											deletePayoutMethodHandler={deletePayoutMethodHandler}
										/>
									);
								}
							})}
						</StyledPayoutMethodsContainer>

						<PayoutMethodForm
							addPayoutMethodHandler={addPayoutMethodHandler}
							createFinancialConnectionHandler={createFinancialConnectionHandler}
						/>
					</StyledInnerPayoutSection>
				</StyledPayoutSection>
			</StyledContentWrapper>
		</StyledContainer>
	);
};

const DashboardPage = () => {
	return (
		<Elements stripe={stripePromise}>
			<DashboardPageContent />
		</Elements>
	);
};

export default DashboardPage;
