import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
	StyledPricingContainer,
	StyledHeading,
	StyledSection,
	StyledPricingWrapper,
	StyledPriceItem,
	StyledPrice,
	StyledTotal,
	StyledTotalPrice,
	StyledCalculatorContainer,
	StyledCalculatorWrapper,
	StyledCalculatorValueContainer,
	StyledCalculatedValue,
	StyledCalculatedValueLabel,
	StyledCalculatorButton,
} from './styles';

import { calculateStripeFee } from 'constants';

const PricingCalculator = ({ dailyRate = 0 }) => {
	const [careProDailyRate, setCareProDailyRate] = useState(0);
	const [careProCost, setCareProCost] = useState(0);
	const [agencyCost, setAgencyCost] = useState(0);
	const [stripeFee, setStripeFee] = useState(0);
	const [totalCharge, setTotalCharge] = useState(0);

	const hourWorked = 12;
	const agencyFee = 0.1;

	useEffect(() => {
		setCareProDailyRate(dailyRate / 100);
	}, [dailyRate]);

	useEffect(() => {
		if (careProDailyRate > 0) {
			const totalCareProCost = careProDailyRate * hourWorked;
			setCareProCost(totalCareProCost);

			const totalAgencyCost = totalCareProCost * agencyFee;
			setAgencyCost(totalAgencyCost);

			const subTotal = totalCareProCost + agencyCost;

			const totalStripeFee = calculateStripeFee(subTotal);

			const chargeWithStripeFee = subTotal + totalStripeFee;

			setStripeFee(totalStripeFee);
			setTotalCharge(chargeWithStripeFee);
		}
	}, [careProDailyRate]);

	return (
		<StyledPricingContainer>
			<StyledHeading>Pricing</StyledHeading>
			<StyledSection>
				<StyledPricingWrapper>
					<StyledPriceItem>Daily Rate</StyledPriceItem>
					<StyledPrice>${careProCost.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
				<StyledPricingWrapper>
					<StyledPriceItem>Agency Fee</StyledPriceItem>
					<StyledPrice>${agencyCost.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
			</StyledSection>
			<StyledSection>
				<StyledPricingWrapper>
					<StyledPriceItem>Subtotal</StyledPriceItem>
					<StyledPrice>{'$' + (careProCost + agencyCost).toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
				<StyledPricingWrapper>
					<StyledPriceItem>Processing Fees</StyledPriceItem>
					<StyledPrice>${stripeFee.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
			</StyledSection>
			<StyledPricingWrapper>
				<StyledTotal>Total</StyledTotal>
				<StyledTotalPrice>{'$' + totalCharge.toFixed(2)}</StyledTotalPrice>
			</StyledPricingWrapper>

			<StyledCalculatorContainer>
				<StyledCalculatorWrapper>
					<StyledCalculatorValueContainer>
						<StyledCalculatedValue>
							${careProDailyRate?.toFixed(2)}
						</StyledCalculatedValue>
						<StyledCalculatedValueLabel>Per Day</StyledCalculatedValueLabel>
					</StyledCalculatorValueContainer>
				</StyledCalculatorWrapper>
			</StyledCalculatorContainer>
		</StyledPricingContainer>
	);
};

export default PricingCalculator;
