import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../../../corev2/Text';
import OnboardingButton from '../../components/OnboardingButton';

import { coreRoutes } from '../../../core/routes/constants';
import { authRoutes } from '../../routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

import { StripeOnboardingProvider } from '../../context/StripeOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useStripeOnboarding } from '../../hooks/useStripeOnboarding';

import {
	StyledOnboardingWelcomePage,
	StyledHeadingSection,
	StyledOnboardingButtonsContainer,
} from './styles';

const OnBoardingWelcomeContent = () => {
	const history = useHistory();

	const {
		authCarePro,
		isAuthenticated,
		hasCompletedProfileOnboarding,
		isStripeVerified,
		hasCompletedStripeOnboarding,
		isRegistryApproved,
		completedDocumentApproval,
		pendingStripeDocumentRequirements,
		refreshAuthCarePro,
	} = useAuth();

	useEffect(() => {
		refreshAuthCarePro();
	}, []);

	const { resetStripeOnboardingState } = useStripeOnboarding();

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.platform.home);
	};

	const goToProfileOnboardingPage = () => {
		history.push(authRoutes.onboarding.profile.home);
	};

	const goToStripeOnboardingPage = () => {
		if (pendingStripeDocumentRequirements) {
			history.push(documentsRoutes.stripeDocuments);
			return;
		}

		history.push(authRoutes.onboarding.stripe.home);
	};

	const goToDocumentsPage = () => {
		history.push(coreRoutes.documents);
	};

	const goToJobPostsPage = () => {
		resetStripeOnboardingState();
		history.push(coreRoutes.jobPosts);
	};

	const profileDisabledStatus = useMemo(() => {
		if (isAuthenticated) {
			return false;
		}
		return true;
	}, [isAuthenticated]);

	const bankingInfoDisabled = useMemo(() => {
		// should be disabled if the user has not created an account or updated their profile info

		if (isAuthenticated && hasCompletedProfileOnboarding) {
			return false;
		}
		return true;
	}, [isAuthenticated, hasCompletedProfileOnboarding]);

	const documentAndRegistryDisabled = useMemo(() => {
		console.log('authCarePro', authCarePro);
		if (pendingStripeDocumentRequirements) {
			return false;
		}
		// should be disabled if the user has not created an account amd updated their profile info  and their banking
		if (isAuthenticated && hasCompletedProfileOnboarding && hasCompletedStripeOnboarding) {
			return false;
		}
		return true;
	}, [authCarePro, isAuthenticated, hasCompletedProfileOnboarding, hasCompletedStripeOnboarding]);

	return (
		<StyledOnboardingWelcomePage>
			<StyledHeadingSection>
				<PageHeading>
					Welcome To The FYI CarePro Community
					{isAuthenticated ? `, ${authCarePro.displayName}` : ''}!
				</PageHeading>

				<Text size='extraLarge'>
					Tired of waiting for weekly or bi-weekly paychecks from payroll companies? Join
					the FYI marketplace and experience timely payments that put you in control of
					your earnings.
					<br />
					<br />
					FYI is a marketplace web service that puts you in control. At FYI, you have the
					power to set your own work schedule and determine your pay rates in
					collaboration with your clients, giving you the autonomy to manage your career
					on your terms.
					<br />
					<br />
					Join FYI today and start earning more for your hard work! Follow the steps below
					to get started.
				</Text>
			</StyledHeadingSection>

			<StyledOnboardingButtonsContainer>
				<OnboardingButton
					text='Create an account or log in'
					onClickHandler={goToOnboardingPage}
					isCompleted={isAuthenticated}
				/>

				<OnboardingButton
					text='Set up your profile by uploading your photo, care providing skills, and a professional statement'
					onClickHandler={goToProfileOnboardingPage}
					isCompleted={hasCompletedProfileOnboarding}
					disabled={profileDisabledStatus}
				/>

				<OnboardingButton
					text={`${
						pendingStripeDocumentRequirements
							? 'Upload documents requested by Stripe.'
							: 'Add your banking information. '
					}`}
					onClickHandler={goToStripeOnboardingPage}
					isCompleted={isStripeVerified}
					disabled={bankingInfoDisabled}
				/>

				<OnboardingButton
					text='Upload supporting documents'
					onClickHandler={goToDocumentsPage}
					isCompleted={completedDocumentApproval}
					disabled={documentAndRegistryDisabled}
				/>

				<OnboardingButton
					text='Registry approval'
					onClickHandler={goToJobPostsPage}
					isCompleted={isRegistryApproved}
					disabled={documentAndRegistryDisabled}
				/>
			</StyledOnboardingButtonsContainer>
		</StyledOnboardingWelcomePage>
	);
};

const OnBoardingWelcomePage = () => {
	return (
		<StripeOnboardingProvider>
			<OnBoardingWelcomeContent />
		</StripeOnboardingProvider>
	);
};

export default OnBoardingWelcomePage;
