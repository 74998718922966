import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandSparkles, faHome } from '@fortawesome/free-solid-svg-icons';

import {
	StyledJobPost,
	StyledInformationContainer,
	StyledJobPostTitle,
	StyledJobPostDescription,
	StyledStatsContainer,
	StyledDateRange,
	StyledStatusContainer,
	StyledStatusIconContainer,
} from './styles';

const JobPost = (props) => {
	const {
		title,
		description,
		startDate,
		endDate,
		status,
		offers,
		careType,
		goToJobPostOfferPage,
	} = props;

	const getTitle = () => {
		switch (status) {
			case 'active':
				return 'This job post has received offers.';

			default:
				return 'Awaiting offers from Care Pros.';
		}
	};

	const formattedStartDate = moment(startDate).format('dddd, MMMM Do, YYYY');
	const formattedEndDate = moment(endDate).format('dddd, MMMM Do, YYYY');

	return (
		<StyledJobPost onClick={goToJobPostOfferPage}>
			<StyledInformationContainer>
				<StyledJobPostTitle>{title}</StyledJobPostTitle>

				<StyledJobPostDescription>{description}</StyledJobPostDescription>
				<StyledDateRange>
					{formattedStartDate} to {formattedEndDate}
				</StyledDateRange>
			</StyledInformationContainer>

			<StyledStatsContainer>
				{/* Status and Offers Section */}
				<StyledStatusContainer>
					<StyledStatusIconContainer title={getTitle()}>
						<FontAwesomeIcon size='sm' icon={faHandSparkles} /> {offers}
					</StyledStatusIconContainer>

					{careType === 'live_in' && (
						<StyledStatusIconContainer title='This is a live in job.'>
							<FontAwesomeIcon size='sm' icon={faHome} />
						</StyledStatusIconContainer>
					)}
				</StyledStatusContainer>
			</StyledStatsContainer>
		</StyledJobPost>
	);
};

export default JobPost;
