import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

import { useAuth } from '../../hooks/useAuth';

import DropDownMenu from './DropDownMenu';
import LoginIndicator from './LoginIndicator';
import { TransparentButton } from '../../../../../corev2/Buttons';

import {
	StyledBanner,
	StyledSecondaryBanner,
	StyledTriBanner,
	StyledHeader,
	StyledWrapper,
	StyledLogo,
	StyledNavButtonsWrapper,
	StyledNavText,
} from './styles';

const NavBar = (props) => {
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);

	const [logoURL, setLogoURL] = useState(
		'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png'
	);

	const history = useHistory();

	const { statusUpdate, isAuthenticated, isRegistryApproved } = useAuth();

	const dropDownHandler = () => {
		if (!isRegistryApproved) return history.push(coreRoutes.dashboard);
		setIsDropDownOpen(!isDropDownOpen);
	};

	const goToHomePage = () => {
		history.push(coreRoutes.root);
	};

	const goToLoginPage = () => {
		history.push(authRoutes.login);
	};

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};

	const goToJobPostsPage = () => {
		history.push(coreRoutes.jobPosts);
	};

	const goToSkillsPage = () => {
		history.push(coreRoutes.skills);
	};
	const goToChatPage = () => {
		history.push(coreRoutes.chat);
	};

	return (
		<>
			{isAuthenticated && (
				<>
					{!isRegistryApproved && (
						<StyledBanner onClick={goToOnboardingPage}>{statusUpdate}</StyledBanner>
					)}
				</>
			)}

			<StyledHeader>
				<StyledWrapper>
					<StyledLogo onClick={goToHomePage} src={logoURL} />

					<StyledNavButtonsWrapper>
						{isAuthenticated && !isRegistryApproved && (
							<TransparentButton onClick={goToOnboardingPage}>
								<StyledNavText>Onboard</StyledNavText>
							</TransparentButton>
						)}

						{isAuthenticated && isRegistryApproved && (
							<>
								<TransparentButton onClick={goToSkillsPage}>
									<StyledNavText>Job Skills</StyledNavText>
								</TransparentButton>
								<TransparentButton onClick={goToJobPostsPage}>
									<StyledNavText>Job posts</StyledNavText>
								</TransparentButton>

								<TransparentButton onClick={goToChatPage}>
									<StyledNavText>Chat</StyledNavText>
								</TransparentButton>
							</>
						)}

						{!isAuthenticated && (
							<>
								<TransparentButton size='small' onClick={goToOnboardingPage}>
									Signup
								</TransparentButton>

								<LoginIndicator onClick={goToLoginPage} />
							</>
						)}

						{isAuthenticated && <LoginIndicator onClick={dropDownHandler} />}

						{isDropDownOpen && <DropDownMenu dropDownHandler={dropDownHandler} />}
					</StyledNavButtonsWrapper>
				</StyledWrapper>
			</StyledHeader>
		</>
	);
};

export default NavBar;
