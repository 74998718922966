import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import TOS from '../../../components/TOS';

import { useUpdateCareProStripeInformationMutation } from '../../../../core/api/mutations/useUpdateCareProStripeInformationMutation';
import { useSetPlatformOnboardMutation } from '../../../api/mutations/useSetPlatformOnboardMutation';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';

import { authRoutes } from '../../../routes/constants';

import { StyledTOSPage } from './styles';

const TOSPage = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [serverError, setServerError] = useState('');

	const { refreshAuthCarePro } = useAuth();
	const { stripeOnboardingState, mutateStripeOnboardingState, resetStripeOnboardingState } =
		useStripeOnboarding();
	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();
	const setPlatformOnboard = useSetPlatformOnboardMutation();

	const tosHandler = async () => {
		try {
			setLoading(true);
			await updateCareProStripeInformation.mutateAsync({
				tos_acceptance_ip_date: Math.floor(Date.now() / 1000).toString(),
			});

			await setPlatformOnboard.mutateAsync();

			mutateStripeOnboardingState('tos', {
				...stripeOnboardingState.tos,
				isCompleted: true,
			});

			refreshAuthCarePro();
			resetStripeOnboardingState();
			history.push(authRoutes.onboarding.home);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Stripe TOS failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.stripe.personal);
	};

	return (
		<StyledTOSPage>
			<PageHeading> Terms of Service </PageHeading>

			<TOS isLoading={loading} tosHandler={tosHandler} />

			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledTOSPage>
	);
};

export default TOSPage;
