import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { PAYMENT_METHOD_MODEL } from '../../../core/api/models';

const createSetupIntent = async () => {
	try {
		const data = await api.graph({
			query: `mutation {
				        createUserStripeIntent(bank_account: ${true},
) 
			}`,
		});

		return data.createUserStripeIntent;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateSetupIntentMutation = () => {
	return useMutation({
		mutationKey: ['createSetupIntent'],
		mutationFn: (values) => {
			return createSetupIntent(values);
		},
	});
};
