import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const createClientFinancialConnectionSession = async () => {
	try {
		const data = await api.graph({
			query: ` {
                createClientFinancialConnectionSession 
            }`,
		});

		return data.createClientFinancialConnectionSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateFinancialConnectionSessionQuery = () => {
	return useMutation({
		mutationKey: ['createClientFinancialConnectionSession'],
		mutationFn: (values) => {
			return createClientFinancialConnectionSession(values);
		},
	});
};
