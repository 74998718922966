import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const createCareProFinancialConnectionSession = async () => {
	try {
		const data = await api.graph({
			query: ` {
                createCareProFinancialConnectionSession 
            }`,
		});

		return data.createCareProFinancialConnectionSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateCareProFinancialConnectionSessionQuery = () => {
	return useMutation({
		mutationKey: ['createCareProFinancialConnectionSession'],
		mutationFn: (values) => {
			return createCareProFinancialConnectionSession(values);
		},
	});
};
