import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Ratespage from './RatesPage';
import SkillsPage from './SkillsPage';
import Photopage from './PhotoPage';
import ProfessionalStatementPage from './ProfessionalStatementPage';

import Steps from '../../../../../corev2/Steps';

import { ProfileOnboardingProvider } from '../../context/ProfileOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useProfileOnboarding } from '../../hooks/useProfileOnboarding';

import { authRoutes } from '../../routes/constants';

import { StyledProfileOnboardingPage, StyledStepsContainer } from './styles';

const ProfileOnboardingContent = (props) => {
	const { children } = props;

	const history = useHistory();
	const location = useLocation();

	const { isAuthenticated } = useAuth();
	const { profileOnboardingState } = useProfileOnboarding();

	useLayoutEffect(() => {
		if (!isAuthenticated) return history.push(authRoutes.login);

		const steps = Object.values(profileOnboardingState);
		const currentStep = steps.find((step) => !step.isCompleted);

		if (currentStep && location.pathname !== currentStep.route) {
			history.push(currentStep.route);
		} else if (!currentStep && location.pathname !== authRoutes.onboarding.home) {
			history.push(authRoutes.onboarding.home);
		}
	}, [isAuthenticated, profileOnboardingState, history]);

	return (
		<StyledProfileOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={profileOnboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledProfileOnboardingPage>
	);
};

const ProfileOnboardingPage = (props) => {
	return (
		<ProfileOnboardingProvider>
			<ProfileOnboardingContent {...props} />
		</ProfileOnboardingProvider>
	);
};

export { Ratespage, SkillsPage, Photopage, ProfessionalStatementPage };
export default ProfileOnboardingPage;
