import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import PageHeading from '../../../../core/components/PageHeading';
import RatesForm from '../../../components/RatesForm';

import { useUpdateCareProInfoMutation } from '../../../api/mutations/useUpdateCareProInfoMutation';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useProfileOnboarding } from '../../../hooks/useProfileOnboarding';

import { authRoutes } from '../../../routes/constants';

import { StyledProfessionalStatementPage } from './styles';

const RatesPage = () => {
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { profileOnboardingState, mutateProfileOnboardingState, resetProfileOnboardingState } =
		useProfileOnboarding();

	const { refreshAuthCarePro } = useAuth();
	const updateCareProInfo = useUpdateCareProInfoMutation();

	const ratesHandler = async (values) => {
		try {
			await updateCareProInfo.mutateAsync({
				hourly_rate: values.hourly_rate,
				live_in_rate: values.live_in_rate,
			});

			mutateProfileOnboardingState('rates', {
				...profileOnboardingState.rates,
				data: {
					hourlyRate: values.hourly_rate,
					liveInRate: values.live_in_rate,
				},
				isCompleted: true,
			});

			refreshAuthCarePro();
			history.push(authRoutes.onboarding.profile.skills);
		} catch (error) {
			console.error('Updating care pro rates failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledProfessionalStatementPage>
			<PageHeading>Rates</PageHeading>

			<RatesForm
				initialValues={{
					hourly_rate: profileOnboardingState.rates.data.hourlyRate,
					live_in_rate: profileOnboardingState.rates.data.liveInRate,
				}}
				ratesHandler={ratesHandler}
				serverError={serverError}
			/>
		</StyledProfessionalStatementPage>
	);
};

export default RatesPage;
