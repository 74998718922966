import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const disputeShift = async ({ shiftId, disputeReason }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					disputeShiftByClient (shift_id: ${shiftId}, dispute_reason: "${disputeReason}") {
						${SHIFT_MODEL}
					}
				}`,
		});

		return data.disputeShiftByClient;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useDisputeShiftMutation = () => {
	return useMutation({
		mutationKey: ['disputeShift'],
		mutationFn: (values) => {
			return disputeShift(values);
		},
	});
};
