import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const createCarePlanV2 = async ({
	userId,
	cc_first_name,
	cc_last_name,
	cc_phone_number,
	cc_relation_to_cr,
	careType,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
        createCarePlanV2(
          user_id: ${userId}
					cc_first_name: "${cc_first_name}",
					cc_last_name: "${cc_last_name}",
					cc_phone_number: "${cc_phone_number}",
					cc_relation_to_cr: "${cc_relation_to_cr}",
					care_type: "${careType}"
        ) {
          id,
					cc_first_name,
					cc_last_name,
					cc_phone_number,
					cr_first_name,
					cr_last_name,
					cc_relation_to_cr,
					cr_dob,
					cr_gender,
					care_zip,
					carePlanSet {
				  	id
				  }
				  careNeedsSet {
				 		id
				  }
        }
      }`,
		});

		return data.createCarePlanV2;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRelationshipMutation = () => {
	return useMutation({
		mutationKey: ['createCarePlanV2'],
		mutationFn: (values) => {
			return createCarePlanV2(values);
		},
	});
};
