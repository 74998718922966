import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledShiftWrapper = styled.div`
	width: 100vw;
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;

	justify-content: center;
	padding: 40px;

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
	width: 100%;
	max-width: 980px;
	margin: auto;

	@media ${mediaQueries.mobileM} {
		padding: 20px 0;
	}
`;

export const StyledShiftContainer = styled.div`
	width: 100%;
	max-width: 1000px; /* Limit the width for better responsiveness */
	box-sizing: border-box;
	//   margin: 0 auto;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: #2b69a3;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px !important;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #2b69a3;
	margin-bottom: 8px;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

export const StyledShiftDetails = styled.div`
	flex: 1;
	background-color: white;
	padding: 30px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	margin-right: 20px;
	border-radius: 10px;
	text-align: left;
`;

export const StyledLabel = styled.span`
	font-weight: 600;
	color: #4a90e2;
	font-size: 14px;

	@media (max-width: 768px) {
		font-size: 12px;
	}

	@media (max-width: 480px) {
		font-size: 11px;
	}
`;

export const StyledValue = styled.span`
	color: #333;
	font-weight: 400;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.4;

	@media (max-width: 768px) {
		font-size: 12px;
	}

	@media (max-width: 480px) {
		font-size: 11px;
	}
`;

export const StyledFieldGroup = styled.div`
	margin-bottom: 10px;
	width: 100%;
`;

export const StyledFieldLabel = styled.label`
	font-size: 14px;
	text-align: left;
	color: #2b69a3;
	margin-bottom: 8px;
	display: block;
`;
export const StyledInputField = styled.input`
	width: 100%;
	box-sizing: border-box;
	padding: 15px;
	font-family: Arial;
	font-size: 16px;

	color: #dee4ea !important;
	margin-bottom: 10px;
	cursor: text;
	border: 1px solid #dee4ea;
	box-shadow: none;

	line-height: 40px;
	padding: 0 12px;
	width: 100%;
	min-height: 42px;
	background-color: transparent;

	::focus {
		outline: thin dotted currentColor;
		border: none;
	}
	&:read-only:focus {
		outline: thin dotted currentColor;
		border: none;
	}
`;

export const StyledButtonGroup = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 10px;
	margin-top: 10px;
`;

export const StyledTextArea = styled.textarea`
	color: #345b7e;
	border: none;
	width: 100%;
	height: 250px;
	border: 1px solid rgba(43, 105, 166, 0.3);
`;

export const StyledTextWithBg = styled.div`
	background-color: #edf6fb;
	flex: 1;
	padding: 30px;
	border-radius: 10px;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	margin-right: 20px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;
	text-align: left;
`;

export const StyledShiftText = styled.p`
	font-size: 14px;
	color: ${colors.primary.primary};
`;
